<template>
  <div class="main">
    <Topbar/>
    <div class="container products-container">
      <div>
        <div class="page-loader" v-if="!isloaded">
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
        </div>
        <div class="row">
          <div class="col-md-12" data-turbolinks="false">
            <div class="landing-title">
              <span class="title">ค้นหาคอร์สเรียน</span>
            </div>
            <h4>คำค้นหา : {{ keyword }} </h4><br>
              <form class=""
                itemprop="potentialAction"
                itemscope="itemscope"
                itemtype="https://schema.org/SearchAction"
                action="/search"
                accept-charset="UTF-8"
                method="get"
              >
                <div class="form-group form-search">
                  <meta
                    itemprop="target"
                    content="https://www.creative-tim.com/search?q={q}"
                  />
                  <input
                    name="q"
                    id="q"
                    class="form-control form-control-search"
                    placeholder="ค้นหาคอร์สเรียน"
                    itemprop="query-input"
                  />
                  <button
                    type="submit"
                    class="btn btn-white btn-round btn-submit"
                  >
                    <i class="icon-search icon-2x"></i>
                  </button>
                </div>
              </form><br><br>
            <div v-if="status == 'error'">
              <div class="container">
                  <br>
                  <article class="card-body mx-auto">
                      <div class="errormargin">
                          <!-- <h1 class="card-title text-center">404</h1> -->
                          <h2 class="card-title text-center">ไม่พบสิ่งที่กำลังค้นหา กรุณาค้นหาใหม่</h2>
                          <p></p>
                      </div>
                  </article>
              </div>  
              
            </div>
            <div v-else>
              <div class="row">
                <div class="col-12 col-sm-6 col-lg-4 p-2" v-for="data in preview" :key="data.course_id">
                  <div class="thumbnail">
                    <a :href="data.link">
                      <img :src="`${data.imgthumbnail}`" :alt="data.imgthumbnail" style="width:100% height:500px" class="imgthum">
                      <div class="caption">
                        <div class="c"><b>ชื่อคอร์สเรียน : {{ data.course_name }}</b></div>
                        <div><b>จำนวนเนื้อหา : {{ data.lesson_content }}</b></div><br>
                        <div class="text-right">
                          <b v-if="data.price == 0">ฟรี</b>
                          <b v-else> {{data.price}} บาท</b>
                        </div>
                        <button type="button" class="btn btn-info btn-sm detail-c"><i class="fa fa-newspaper-o" aria-hidden="true"></i> ดูรายละเอียดเพิ่มเติม</button>
                      </div>              
                    </a>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div v-if="status === 'success'">
                  <button type="submit" @click="loadseemore" class="btn btn-purple btn-round btn-fill">
                    <i class="fa fa-newspaper-o" aria-hidden="true"></i> ดูเพิ่มเติม
                  </button>
                </div>
                <div v-else>
                  <h3>ไม่พบเนื้อหา</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import axios from 'axios';
// import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Coursesearch',
  data() {
    return {
      preview: [],
      category: [],
      isloaded: false,
      selectcategory: '',
      status: '',
      keyword: '',
    }
  },
  components: {
    Topbar,
    Footer
  },
  created() {
      this.getcategory();
      this.loadpreview();
  }, 
  methods: {
      getcategory() {
        var formDataCategory = new FormData();
          formDataCategory.append('action', 'getcategory');
            axios({
              method: 'post',
              url: api.getcategory,
              headers: { 'Content-Type': 'application/json' },
              data: formDataCategory,
            })
            .then((res) => {
              this.category = res.data.result
            })
      },
      loadpreview() {
        var formData = new FormData();
          formData.append('action', 'getsearch');
          formData.append('q', this.$route.query.q);
            axios({
              method: 'post',
              url: api.coursepreview,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              this.preview = response.data.result
              this.status = response.data.status
              this.keyword = this.$route.query.q
            });
      },
      loadseemore () {
        var formData = new FormData();
          formData.append('action', 'getsearch');
          formData.append('q', this.$route.query.q);
          formData.append('c', this.c);
          // alert (this.$route.query.c +"-"+this.selectcategory)
            axios({
              method: 'post',
              url: api.coursepreview,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              this.preview = response.data.result
              this.status = response.data.status
              this.c = response.data.c
            });
      },

    },
    mounted() {
      setInterval(
        document.onreadystatechange = () => {
          if (document.readyState == "complete") { 
          this.isloaded = true;
          } 
        }
      ,2000);
    },
}
</script>
<style>


select.form-control{
  border-radius: 0px;
  background-size: cover;
  width: 100%;
  height: auto;
}

.main .title {
    font-size: 22px;
    font-weight: 300;
    margin: 7px 0;
    color: #000;
}
.form-search .form-control-search {
    background-color: #3c2e5e;
    box-shadow: none;
    border: medium none;
    border-radius: 30px;
    box-shadow: 0 0 0;
    color: #FFFFFF;
    display: block;
    font-size: 20px;
    font-weight: 300;
    height: 50px;
    line-height: 1.42857;
    padding: 6px 20px;
    transition: background-color .2s;
}
.thumbnail {
    display: block;
    padding: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    line-height: 1.428571429;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 12px 34px rgba(0, 0, 0, 0.12);
}
.imgthum {
    border-radius: 10px;
}
.thumbnail>img, .thumbnail a>img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
}
div.c{
  text-decoration: none;
  text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */ 
  display: block; overflow: hidden; 
  white-space: nowrap; 
  width: auto; /* กำหนดความกว้าง */
  text-align: left;
}
button.detail-c{
        width: 100%;
        background-color: #2d1961; /* Green */
        border: 2px solid #2d1961;
        color: #ffffff;
        /* padding: 15px 150px; */
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        transition: 0.3s;
}
</style>