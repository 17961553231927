<template>
<div>
  <div class="footer">
    <div class="overlayer">
      <div class="container">
          <div class="col-md-12">
            <div class="social-area text-center">
                <span itemprop="name" style="color: #FFFFFF">สถาบันภาษา มหาวิทยาลัยราชภัฏเพชรบูรณ์ อาคารปฏิบัติการทางภาษา ชั้น 4 (LC) </span><br>
                <span itemprop="name" style="color: #FFFFFF">83 หมู่ 11 ถนนสระบุรี-หล่มสัก ตำบลสะเดียง อำเภอเมือง จังหวัดเพชรบูรณ์ 67000</span><br>
                <span itemprop="name" style="color: #FFFFFF">โทรศัพท์ 056-717-168</span>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {};
</script>

<style>
</style>