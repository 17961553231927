<template>
<div class="main">
  <div>
    <Topbar/>
        <div class="container">
        <br>
        <article class="card-body mx-auto" style="max-width: 500px;">
          <div v-if="status == 'success'">
            <div v-if="statusconfirm == 'success'">
                <br><br><br><br>
                  <img src="//limooc.pcru.ac.th/verify.png" class="imgresponsive" alt="verify">
                  <h2 class="card-title text-center">ยืนยันบัญชีเรียบร้อย</h2>
                  <p></p>
            </div>
          </div>
          <div v-else>
            <div class="errormargin">
                <h2 class="card-title text-center">Link หมดอายุ</h2>
                <p></p>
            </div>
          </div>
        </article><br><br>
      </div> 
    <Footer/>
  </div>
</div>
</template>
<script>
// import $ from 'jquery'
import axios from 'axios';
// import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Main',
  data() {
    return {
      newpassword: '',
      confirmnewpassword: '',
      status: '',
      username: '',
      statusconfirm: '',
    }
  },
  components: {
    Topbar,
    Footer
  },
    created() {     
      var accesstoken = this.$route.query.r;
      if ( accesstoken !== null) {
          var formDataaccesstoken = new FormData();
              formDataaccesstoken.append('accesstoken', accesstoken);
              axios({
                  method: 'post',
                  url: api.confirmcheck,
                  headers: { 'Content-Type': 'application/json' },
                  data: formDataaccesstoken,
              })
              .then((response) => {
                this.status = response.data.status
                this.username = response.data.username
                  if ( response.data.status === 'success') {
                    var formDataconfirm = new FormData();
                        formDataconfirm.append('action', 'changeconfirm');   
                        formDataconfirm.append('username', this.username);                  
                        axios({
                            method: 'post',
                            url: api.changeconfirm,
                            headers: { 'Content-Type': 'application/json' },
                            data: formDataconfirm,
                        })
                        .then((res) => {
                            this.statusconfirm = res.data.status
                        })
                }

              });
      }
      window.setTimeout(function(){ 
          window.location="/";
      } ,5000)
    },
    
}
</script>
<style>
.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 45em;
    max-width: 100%;
    padding: 5em;
    padding-top: 5em;
    padding-right: 5em;
    padding-bottom: 5em;
    padding-left: 5em;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
}
select.form-control{
      border-radius: 50px;
      background-size: cover;
      width: 100%;
      height: auto;
}
.imgresponsive {
    max-width: 100%;
    height: auto;
}
</style>