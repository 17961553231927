import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Swal from 'sweetalert2'
import VueLocalStorage from 'vue-localstorage'
import $ from 'jquery'
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
// import VueProgress from 'vue-progress-path'
import 'vue-progress-path/dist/vue-progress-path.css'
// import browserDetect from "vue-browser-detect-plugin";


Vue.use(VuePlyr);
// Vue.use(browserDetect);
Vue.use(VueAxios, axios, Swal, VueLocalStorage, $); 
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')