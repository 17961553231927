<template>
  <div class="main">
    <Topbar/>
    <div class="container products-container">
      <div>
        <div class="page-loader" v-if="!isloaded">
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
        </div>
        <div class="row">
          <div class="col-md-12" data-turbolinks="false">
            <div class="landing-title">
              <span class="title">คอร์สเรียนทั้งหมด</span>
            </div>
            <div class="row">
              <div class="col-md-4 col-lg-4"></div>
              <div class="col-md-4 col-lg-4">
                <div class="form-group">
                  <select  @change="onChange($event)" class="form-control" v-model="selectcategory">
                    <option value="" selected disabled>เลือกหมวดหมู่</option>
                    <option value="0">ทั้งหมด</option>
                    <option v-for="data in category" :key="data.course_category_id" :value="data.course_category_id"> {{ data.name_th}} </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 col-lg-4"></div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-4 p-2" v-for="data in preview" :key="data.course_id">
                <div class="thumbnail">
                  <a :href="data.link">
                    <img :src="`${data.imgthumbnail}`" :alt="data.imgthumbnail" style="width:100% height:500px" class="imgthum">
                    <div class="caption">
                      <div class="c"><b>ชื่อคอร์สเรียน : {{ data.course_name }}</b></div>
                      <div><b>จำนวนเนื้อหา : {{ data.lesson_content }}</b></div><br>
                      <div class="text-right">
                        <b v-if="data.price == 0">ฟรี</b>
                        <b v-else> {{data.price}} บาท</b>
                      </div>
                      <button type="button" class="btn btn-info btn-sm detail-c"><i class="fa fa-newspaper-o" aria-hidden="true"></i> ดูรายละเอียดเพิ่มเติม</button>
                    </div>              
                  </a>
                </div>
              </div>
            </div>
            <div class="text-center">
              <div v-if="status === 'success'">
                <button type="submit" @click="loadseemore" class="btn btn-purple btn-round btn-fill">
                  <i class="fa fa-newspaper-o" aria-hidden="true"></i> ดูเพิ่มเติม
                </button>
              </div>
              <div v-else>
                <h3>ไม่พบเนื้อหา</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import axios from 'axios';
// import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Coursepreview',
  data() {
    return {
      preview: [],
      category: [],
      isloaded: false,
      selectcategory: '',
      c: '',
      status: '',
    }
  },
  components: {
    Topbar,
    Footer
  },
  created() {
      this.getcategory();
      this.loadpreview();
  }, 
  methods: {
      getcategory() {
        var formDataCategory = new FormData();
          formDataCategory.append('action', 'getcategory');
            axios({
              method: 'post',
              url: api.getcategory,
              headers: { 'Content-Type': 'application/json' },
              data: formDataCategory,
            })
            .then((res) => {
              this.category = res.data.result
            })
      },
      loadpreview() {
        var formData = new FormData();
          formData.append('action', 'getpreview');
          formData.append('course_category_id', this.$route.query.c);
            axios({
              method: 'post',
              url: api.coursepreview,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              this.preview = response.data.result
              this.c = response.data.c
              this.status = response.data.status
            });
      },
      loadseemore () {
        var formData = new FormData();
          formData.append('action', 'loadseemore');
          formData.append('course_category_id', this.selectcategory);
          formData.append('course_category_id_url', this.$route.query.c);
          formData.append('c', this.c);
          // alert (this.$route.query.c +"-"+this.selectcategory)
            axios({
              method: 'post',
              url: api.coursepreview,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              this.preview = response.data.result
              this.status = response.data.status
              this.c = response.data.c
            });
      },
      onChange(event) {
          // console.log(event.target.value)
        var formData = new FormData();
          formData.append('action', 'getpreview');
          formData.append('course_category_id', event.target.value);
            axios({
              method: 'post',
              url: api.coursepreview,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              this.preview = response.data.result
              this.status = response.data.status
            });
      }
    },
    mounted() {
      setInterval(
        document.onreadystatechange = () => {
          if (document.readyState == "complete") { 
          this.isloaded = true;
          } 
        }
      ,2000);
    },
}
</script>
<style>
select.form-control{
  border-radius: 0px;
  background-size: cover;
  width: 100%;
  height: auto;
}

.main .title {
    font-size: 22px;
    font-weight: 300;
    margin: 7px 0;
    color: #000;
}

.thumbnail {
    display: block;
    padding: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    line-height: 1.428571429;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 12px 34px rgba(0, 0, 0, 0.12);
}
.imgthum {
    border-radius: 10px;
}
.thumbnail>img, .thumbnail a>img {
    display: block;
    /* max-width: 100%;
    height: auto; */
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
}
div.c{
  text-decoration: none;
  text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */ 
  display: block; overflow: hidden; 
  white-space: nowrap; 
  width: auto; /* กำหนดความกว้าง */
  text-align: left;
}
button.detail-c{
        width: 100%;
        background-color: #2d1961; /* Green */
        border: 2px solid #2d1961;
        color: #ffffff;
        /* padding: 15px 150px; */
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        transition: 0.3s;
}
</style>