<template>
<div class="main">
    <div v-if="approve === 'no'">
    <Topbar/>
          <div class="container">
          <br>
            <article class="card-body mx-auto text-center"> 
                <img src="https://production.nackpolice.co/approve.png" class="imgresponsive" alt="approve"> 
                <h2 class="card-title text-center">กรุณาลงทะเบียนหรือรอการ Approve จากผู้ดูแลระบบก่อน</h2>
                <br><br>
            </article>
        </div>
    <Footer/>         
    </div>
    <div v-else>
    <Topbar/>
        <div>
            <div class="page-loader" v-if="!isloaded">
            <div class="cube"></div>
            <div class="cube"></div>
            <div class="cube"></div>
            <div class="cube"></div>
            </div>
        </div>
          <div class="container" v-for="data in courses" :key="data.course_id">           
            <div class="row"> 
                    <div class="container"> 
                        <div class="col-md-8"> 
                            <div >
                                <h3>{{ data.course_name }}</h3> 
                                <h5><i class="fa fa-caret-square-o-down"></i> หมวดหมู่ : {{ data.name_th }}</h5> <br> 
                            </div> 
                        </div>
                        <div class="col-md-4 text-right"> 
                            <div >
                                <button class="btn btn-info" role="button" @click="refreshprogress">Refresh</button>
                            </div>
                        </div>
                        <div class="col-md-12"> 
                            <div >
                                <h4>ความก้าวหน้าในการเรียน: <font color="green">{{ progress }}</font> %</h4> 
                            </div> 
                            <div class="progress">
                                <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                                aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" :style="`width:${progress}%`">
                                    {{ progress }}%
                                </div>
                            </div>
                                <h4>ผ่านเกณฑ์การเรียน : <font color="green">{{ per_success }}</font> %</h4>
                                *** ผู้เรียนจะต้องทำแบบทดสอบก่อนเรียนก่อนที่จะเริ่มเข้าสู่บทเรียน
                            <hr>
                            Learning Path
                            <!--  pretest -->
                            <div v-if="expretest == 'success'">
                                <div class="pb-itemex pb-course-theme">  
                                    <div class="pb-item-order-col"><div>#</div></div>  
                                    <div class="pb-item-content-col">
                                        <div data-part="si_body">แบบทดสอบก่อนเรียน</div>
                                        <div v-if="expreres == 'success'">
                                            <div class="pb-si-progress pb-course-theme" data-data="100">
                                                <div style="width: 100%;"></div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="pb-si-progress pb-course-theme" data-data="0">
                                                <div style="width: 0%;"></div>
                                            </div>
                                        </div>
                                        <div class="pb-item-si-member-panel">
                                            <div id="pb-si-member" class="pb-si-member-list pb-course-theme">
                                                <a style="color: #3100b8;" :href="`../pretestq/${data.course_id}`" target="_blank">
                                                    <div class="pb-si-member-list-item media pb-course-theme">  
                                                        <div class="media-left" data-part="si-member-progress"></div>  
                                                        <div class="media-body">    
                                                            <div data-part="si-member-title">      
                                                                <i class="fa fa-pencil-square-o"></i> แบบทดสอบก่อนเรียน (Pre-test)    
                                                            </div>  
                                                        </div>
                                                        <div class="media-right" data-part="si-member-progress-holder">
                                                            <div>
                                                                <span class="cvposter-progress-holder">
                                                                    <div v-if="expreres == 'success'">
                                                                        100 %
                                                                    </div>
                                                                    <div v-else>
                                                                        0 %
                                                                    </div>
                                                                </span>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>

                            <!-- learning path -->
                            <div class="pb-item pb-course-theme" v-for="(data, c_no) in getlearning" :key="data.leaning_path_id">  
                                <div class="pb-item-order-col"><div> {{ c_no+=1 }}</div></div>  
                                <div class="pb-item-content-col">
                                    <div data-part="si_body">{{ data.leaning_path_name }}</div>
                                        <div class="pb-si-progress pb-course-theme">
                                            <div :style="`width:${data.successpercent}%`"></div>
                                        </div>
                                            <div class="pb-item-si-member-panel">
                                                <div id="pb-si-member" class="pb-si-member-list pb-course-theme">
                                                    <div v-if="expreres == 'success'">
                                                        <a style="color: #3100b8;" :href="`../video/${id}?q=${ data.leaning_path_id }`" target="_blank">
                                                        <div class="pb-si-member-list-item media pb-course-theme">  
                                                            <div class="media-left" data-part="si-member-progress"></div>  
                                                            <div class="media-body">    
                                                                <div data-part="si-member-title">      
                                                                    <i class="fa fa-video-camera"></i> {{ data.leaning_path_name }}    
                                                                </div>  
                                                            </div>
                                                            <div class="media-right" data-part="si-member-progress-holder">
                                                                <div>
                                                                    <span class="cvposter-progress-holder">
                                                                        <div v-if="data.successpercent == null">
                                                                            0 %
                                                                        </div>
                                                                        <div v-else>
                                                                            {{ data.successpercent }} %
                                                                        </div>
                                                                    </span>
                                                                    <span></span>
                                                                </div>  
                                                            </div>
                                                        </div>
                                                        </a>
                                                    </div>
                                                    <div v-else>
                                                        <div class="pb-si-member-list-item media pb-course-theme">  
                                                            <div class="media-left" data-part="si-member-progress"></div>  
                                                            <div class="media-body">    
                                                                <div data-part="si-member-title">      
                                                                    <i class="fa fa-video-camera"></i> {{ data.leaning_path_name }}    
                                                                </div>  
                                                            </div>
                                                            <div class="media-right" data-part="si-member-progress-holder">
                                                                <div>
                                                                    <span class="cvposter-progress-holder">
                                                                        <div v-if="data.successpercent == null">
                                                                            0 %
                                                                        </div>
                                                                        <div v-else>
                                                                            {{ data.successpercent }} %
                                                                        </div>
                                                                    </span>
                                                                    <span></span>
                                                                </div>  
                                                            </div>
                                                        </div>                                      
                                                    </div>
                                                    
                                                </div>
                                            </div> 
                                </div>
                            </div>
                            <!-- post test -->
                            <div v-if="exposttest == 'success'">
                                <div class="pb-itemex pb-course-theme">  
                                    <div class="pb-item-order-col"><div>#</div></div>  
                                    <div class="pb-item-content-col">
                                        <div data-part="si_body">แบบทดสอบหลังเรียน</div>
                                        <div v-if="expostres == 'success'">
                                            <div class="pb-si-progress pb-course-theme" data-data="100">
                                                <div style="width: 100%;"></div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="pb-si-progress pb-course-theme" data-data="0">
                                                <div style="width: 0%;"></div>
                                            </div>
                                        </div>
                                        <div class="pb-item-si-member-panel">
                                            <div id="pb-si-member" class="pb-si-member-list pb-course-theme">
                                                <div v-if="progress > per_success-1">
                                                <a style="color: #3100b8;" :href="`../posttestq/${data.course_id}`" target="_blank">
                                                    <div class="pb-si-member-list-item media pb-course-theme">  
                                                        <div class="media-left" data-part="si-member-progress"></div>  
                                                        <div class="media-body">    
                                                            <div data-part="si-member-title">      
                                                                <i class="fa fa-pencil-square-o"></i> แบบทดสอบหลังเรียน (Post-test)    
                                                            </div>  
                                                        </div>
                                                        <div class="media-right" data-part="si-member-progress-holder">
                                                            <div>
                                                                <span class="cvposter-progress-holder">
                                                                    <div v-if="expostres == 'success'">
                                                                        100 %
                                                                    </div>
                                                                    <div v-else>
                                                                        0 %
                                                                    </div>
                                                                </span>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                </div>
                                                <div v-else>
                                                    <div class="pb-si-member-list-item media pb-course-theme">  
                                                        <div class="media-left" data-part="si-member-progress"></div>  
                                                        <div class="media-body">    
                                                            <div data-part="si-member-title">      
                                                                <i class="fa fa-pencil-square-o"></i> แบบทดสอบหลังเรียน (Post-test) *** ยังเรียนไม่ผ่านเกณฑ์    
                                                            </div>  
                                                        </div>
                                                        <div class="media-right" data-part="si-member-progress-holder">
                                                            <div>
                                                                <span class="cvposter-progress-holder">
                                                                    <div v-if="expostres == 'success'">
                                                                        100 %
                                                                    </div>
                                                                    <div v-else>
                                                                        0 %
                                                                    </div>
                                                                </span>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div><br><br>                            
                        </div> 
                    </div>
            </div>
        </div>
        <div>
             <Footer/>
        </div>     
    </div>
</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
    name: 'courses',
    data() {
        return {
            isloaded: false,
            id : this.$route.params.id,
            expretest: '',
            expreres: '',
            exposttest: '',
            expostres: '',
            progress: '0',
            courses: [],
            checkregistercourse: '',
            session_status: '',
            users_id: '',
            // registercourse status
            msgapprove:'',
            approve:'',
            linklearning:'',
            // getlearning
            getlearning: [],
            c_no : 0,
            per_success: '',
        }
    },
    components: {
        Topbar,
        Footer
    },
    mounted() {
        setInterval(
        document.onreadystatechange = () => {
            if (document.readyState == "complete") { 
            this.isloaded = true;
            } 
        }
        ,3000);
    },

    created() {

        // get course detail
        this.getcourse();
        // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    this.users_id = response.data.users_id
                    // console.log(response.data.status)
                    if ( response.data.status == 'error') {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "กรุณาเข้าสู่ระบบ",
                            showConfirmButton: false,
                            timer: 5000,
                        },window.setTimeout(function(){ 
                            window.location="/login";
                            // location.reload();
                        } ,3000))
                    } else {
                        if ( response.data.confirm_account === 'false') {
                            window.location="/confirm";
                        } else {
                        // get registercourse
                        var formDatacheckregistercourse = new FormData();
                            formDatacheckregistercourse.append('action', 'checkregistercourse');
                            formDatacheckregistercourse.append('course_id', this.$route.params.id);
                            formDatacheckregistercourse.append('users_id', response.data.users_id);
                            axios({
                                method: 'post',
                                url: api.purchases,
                                headers: { 'Content-Type': 'application/json' },
                                data: formDatacheckregistercourse,
                            })
                            .then((response) => {
                                this.checkregistercourse = response.data.status
                                this.approve = response.data.approve
                                // console.log(response.data.approve)
                                if ( response.data.approve === 'yes') {
                                var formDataLearningdetail = new FormData();
                                    formDataLearningdetail.append('action', 'getlearning');
                                    formDataLearningdetail.append('course_id', this.$route.params.id);
                                    formDataLearningdetail.append('users_id', this.users_id);
                                    axios({
                                        method: 'post',
                                        url: api.getlearning,
                                        headers: { 'Content-Type': 'application/json' },
                                        data: formDataLearningdetail,
                                    })
                                    .then((response) => {
                                        this.getlearning = response.data.result
                                        // console.log(response.data.result)
                                    });
                                    // get progressbar
                                    var formDataProgress = new FormData();
                                        formDataProgress.append('action', 'progress');
                                        formDataProgress.append('id', this.$route.params.id);
                                        formDataProgress.append('users_id', this.users_id);
                                        axios({
                                            method: 'post',
                                            url: api.progress,
                                            headers: { 'Content-Type': 'application/json' },
                                            data: formDataProgress,
                                        })
                                        .then((response) => {
                                            this.progress = response.data.prog
                                            this.per_success = response.data.per_success
                                            // console.log(this.progress)
                                        });
                                    // get example
                                    // pretest
                                    var formDataExpretest = new FormData();
                                        formDataExpretest.append('action', 'expretest');
                                        formDataExpretest.append('id', this.$route.params.id);
                                        formDataExpretest.append('users_id', this.users_id);
                                        axios({
                                            method: 'post',
                                            url: api.expretest,
                                            headers: { 'Content-Type': 'application/json' },
                                            data: formDataExpretest,
                                        })
                                        .then((response) => {
                                            this.expretest = response.data.status
                                            this.expreres = response.data.exres
                                            // console.log(this.exres)
                                        });
                                    // posttest
                                    var formDataExposttest = new FormData();
                                        formDataExposttest.append('action', 'exposttest');
                                        formDataExposttest.append('id', this.$route.params.id);
                                        formDataExposttest.append('users_id', this.users_id);
                                        axios({
                                            method: 'post',
                                            url: api.exposttest,
                                            headers: { 'Content-Type': 'application/json' },
                                            data: formDataExposttest,
                                        })
                                        .then((response) => {
                                            this.exposttest = response.data.status
                                            this.expostres = response.data.exres
                                            // console.log(this.exposttest)
                                        });
                                }
                            });
                        }
                    
                    }
                });
        } else {
            window.location="/login";
        }
    },
    methods: {
        reloadPage(){
            window.location.reload()
        },
        getcourse() {
        var formData = new FormData();
            formData.append('action', 'getcourselearning');
            formData.append('id', this.$route.params.id);
            axios({
                method: 'post',
                url: api.courses,
                headers: { 'Content-Type': 'application/json' },
                data: formData,
            })
            .then((response) => {
                this.courses = response.data.result
                // console.log(response.data)
            });
        },
        refreshprogress() {
            var formDataLearningdetail = new FormData();
                formDataLearningdetail.append('action', 'getlearning');
                formDataLearningdetail.append('course_id', this.$route.params.id);
                formDataLearningdetail.append('users_id', this.users_id);
                axios({
                    method: 'post',
                    url: api.getlearning,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataLearningdetail,
                })
                .then((response) => {
                    this.getlearning = response.data.result
                    // console.log(response.data.result)
                });            
            // get progressbar
            var formDataProgress = new FormData();
                formDataProgress.append('action', 'progress');
                formDataProgress.append('id', this.$route.params.id);
                formDataProgress.append('users_id', this.users_id);
                axios({
                    method: 'post',
                    url: api.progress,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataProgress,
                })
                .then((response) => {
                    this.progress = response.data.prog
                    this.per_success = response.data.per_success
                    // console.log(this.progress)
                });
            // get example
            // pretest
            var formDataExpretest = new FormData();
                formDataExpretest.append('action', 'expretest');
                formDataExpretest.append('id', this.$route.params.id);
                formDataExpretest.append('users_id', this.users_id);
                axios({
                    method: 'post',
                    url: api.expretest,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataExpretest,
                })
                .then((response) => {
                    this.expretest = response.data.status
                    this.expreres = response.data.exres
                    // console.log(this.exres)
                });
            // posttest
            var formDataExposttest = new FormData();
                formDataExposttest.append('action', 'exposttest');
                formDataExposttest.append('id', this.$route.params.id);
                formDataExposttest.append('users_id', this.users_id);
                axios({
                    method: 'post',
                    url: api.exposttest,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataExposttest,
                })
                .then((response) => {
                    this.exposttest = response.data.status
                    this.expostres = response.data.exres
                    // console.log(this.exposttest)
                });
        }
    }
}
</script>
<style>
    .errormargin {
        margin-top: 200px;
        margin-bottom: 300px;
    }
    img.logo {
        border-radius: 50%;
    }
    .responsive-iframe {
    width: 100%;
    }
    .btn-success {
        color: #ffffff;
        background-color: #5cb85c;
        border-color: #4cae4c;
    }
    .btn-danger {
        color: #ffffff;
        background-color: #FF3B30;
        border-color: #FF3B30;
    }
    .centerbutton { 
        display: flex; justify-content: center; 
    }
    /* progressbar */
    /* .pb-item.pb-course-theme:nth-child(2n+1) {
    background-color: #C2E5D4;
    } */


    /* example */
    .pb-itemex:nth-child(2n+1) {
        background: #C2E5D4;
    }

    .pb-itemex {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        margin: 10px 0;
    }
    /* end */
    .pb-item:nth-child(2n+1) {
        background: #EEE1C4;
    }
    .pb-item:nth-child(2n) {
        background: #BCDBDB;
    }

    #pb-wrapper {
        font-size: 14px;
    }
    .pb-item-order-col > div {
        position: relative;
        font-size: 1.2em;
        background: white;
        width: 30px;
        line-height: 30px;
        text-align: center;
        height: 30px;
        border: #f50057 solid 4px;
        box-sizing: content-box;
        border-radius: 30%;
        left: -15px;
    }
    a .pb-si-member-list-item:hover {
        text-decoration: none;
        color: black;
    }
    .pb-si-member-list.pb-course-theme > a {
        color: #3100b8;
    }
    .pb-si-progress {
        width: 100%;
        height: 8px;
        position: relative;
        background: white;
        margin-bottom: 10px;
        box-shadow: inset 1px 1px 1px rgba(0,0,0,0.4);
        border-radius: 10px;
    }
    .pb-si-progress > div {
        position: absolute;
        top: 0;
        left: 0;
        background: #1C7664;
        bottom: 0;
        width: 0;
        transition: width 1s;
    }
    .pb-item {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        margin: 10px 0;
    }
    .pb-item-si-member-panel {
        background: rgba(255,255,255,0.3);
        padding: 5px;
        border-radius: 10px;
        box-shadow: 0 0 1px rgba(0,0,0,0.5);
        transition: background 1s;
    }
    .pb-item-content-col {
        /* width: calc(100% - 70px); */
        width: calc(100% - 40px);
    }
    .imgresponsive {
        max-width: 100%;
        height: auto;
    }
</style>