<template>
  <div class="row">
    <div class="col-md-12" data-turbolinks="false">
      <div class="landing-title">
        <span class="title">คอร์สเรียนล่าสุด</span>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4 p-2" v-for="data in preview" :key="data.course_id">
          <div class="thumbnail">
            <a :href="data.link">
              <img :src="`${data.imgthumbnail}`" :alt="data.imgthumbnail" style="width:100% height:500px" class="imgthum">
              <div class="caption">
                <div class="c"><b>ชื่อคอร์สเรียน : {{ data.course_name }}</b></div>
                <div><b>จำนวนเนื้อหา : {{ data.lesson_content }}</b></div><br>
                <div class="text-right">
                  <b v-if="data.price == 0">ฟรี</b>
                  <b v-else> {{data.price}} บาท</b>
                </div>
                <button type="button" class="btn btn-info btn-sm detail-c"><i class="fa fa-newspaper-o" aria-hidden="true"></i> ดูรายละเอียดเพิ่มเติม</button>
              </div>              
            </a>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a href="/courses" class="btn btn-purple btn-round btn-fill"
          ><i class="fa fa-newspaper-o" aria-hidden="true"></i> ดูคอร์สเรียนทั้งหมด</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import api from '@/urlapi.js'
export default {
  name: 'Coursepreview',
  data() {
    return {
      preview: [],
      category: [],
    }
  },
  created() {
      this.loadpreview();
  }, 
  methods: {
      loadpreview() {
        var formData = new FormData();
          formData.append('action', 'getnewcourse');
          formData.append('course_category_id', '');
            axios({
              method: 'post',
              url: api.coursepreview,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              this.preview = response.data.result
            });
      }
    }, 
};
</script>

<style>

.thumbnail {
    display: block;
    padding: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    line-height: 1.428571429;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 12px 34px rgba(0, 0, 0, 0.12);
}
.imgthum {
    border-radius: 10px;
}
.thumbnail>img, .thumbnail a>img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
}
div.c{
  text-decoration: none;
  text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */ 
  display: block; overflow: hidden; 
  white-space: nowrap; 
  width: auto; /* กำหนดความกว้าง */
  text-align: left;
}
button.detail-c{
        width: 100%;
        background-color: #2d1961; /* Green */
        border: 2px solid #2d1961;
        color: #ffffff;
        /* padding: 15px 150px; */
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        transition: 0.3s;
}
</style>