<template>
<div class="main">
  <div>
    <Topbar/>
          <div class="container">           
            <div class="row"> 
              <div class="container"> 
                  <!-- change profile --> 
                  <div class="col-md-6"> 
                      <h3>ข้อมูลบัญชีผู้ใช้</h3><br><br>
                      <table class="table table-hover" v-for="data in getprofile" :key="data.users_id">
                        <thead>
                        </thead>
                        <tbody>
                          <tr class="text-left">
                            <td><i class="fa fa-user-circle-o"></i> ชื่อ - นามสกุล</td>
                            <td>{{ data.prefix_name+data.firstname+ " "+data.lastname }}</td>
                          </tr>
                          <tr class="text-left">
                            <td><i class="fa fa-map-marker"></i>  ที่อยู่ </td>
                            <td>{{ data.address }}</td>
                          </tr>
                          <tr class="text-left">
                            <td><i class="fa fa-envelope"></i> E-mail Address </td>
                            <td> {{ data.email }}</td>
                          </tr>   
                          <tr class="text-left">
                            <td><i class="fa fa-phone"></i> เบอร์โทรศัพท์ </td>
                            <td> {{ data.tel }}</td>
                          </tr>         
                          <tr class="text-left">
                            <td><i class="fa fa-user"></i> ชื่อผู้ใช้งาน </td>
                            <td> {{ data.username }}</td>
                          </tr>                                                  
                        </tbody>
                      </table>
                      <!-- Button trigger modal -->
                      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                        <i class="icon-edit-sign"></i> แก้ไขข้อมูลบัญชีผู้ใช้
                      </button><br><br>
                      <!-- Modal -->
                      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">แก้ไขข้อมูลบัญชีผู้ใช้</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form id="formall">
                              <div class="modal-body">
                                <table class="table table-hover" v-for="data in getprofile" :key="data.users_id">
                                  <thead>
                                  </thead>
                                  <tbody>
                                    <tr class="text-left">
                                      <td>คำนำหน้า</td>
                                      <td> 
                                        <select v-model="prefix_id_selected" class="form-control" id="prefix" required>
                                            <option disabled value="">{{data.prefix_name}}</option>
                                            <option v-for="datapf in prefix" :key="datapf.prefix_id" :value="datapf.prefix_id"> {{ datapf.prefix_name}} </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr class="text-left">
                                      <td>ชื่อ</td>
                                      <td> <input type="text" class="form-control" id='firstname' :value="data.firstname" required></td>
                                      <td> <input type="hidden" class="form-control" id='users_id' :value="data.users_id"></td>
                                    </tr>
                                    <tr class="text-left">
                                      <td>นามสกุล </td>
                                      <td> <input type="text" class="form-control" id='lastname' :value="data.lastname" required></td>
                                    </tr>
                                    <tr class="text-left">
                                      <td>ที่อยู่ </td>
                                      <td> <input type="text" class="form-control" id='address' :value="data.address" required></td>
                                    </tr>
                                    <tr class="text-left">
                                      <td>E-mail Address </td>
                                      <td> <input type="text" class="form-control" id='email' :value="data.email" disabled required></td>
                                    </tr>   
                                    <tr class="text-left">
                                      <td>เบอร์โทรศัพท์ </td>
                                      <td> <input type="text" class="form-control" id='tel' maxlength="10" :value="data.tel" required></td>
                                    </tr>         
                                    <tr class="text-left">
                                      <td>ชื่อผู้ใช้งาน </td>
                                      <td> <input type="text" class="form-control"  :value="data.username" required disabled></td>
                                      <!-- <td>{{ data.dateAdd }}</td> -->
                                    </tr>                                                  
                                  </tbody>
                                </table>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal"> ปิด</button>
                                <button type="button" id="submit" class="btn btn-success" data-dismiss="modal" @click="updateaccount"><i class="icon-edit-sign"></i> แก้ไขข้อมูล</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <hr>
                  </div>
                  <!-- change password --> 
                  <div class="col-md-6"> 
                      <h3>เปลี่ยนรหัสผ่าน</h3><br><br>
                      <form id="formchangepassword">
                        <table class="table table-hover">
                          <thead>
                          </thead>
                          <tbody>
                            <tr class="text-left">
                              <td>รหัสผ่านเดิม</td>
                              <td><input type="password" class="form-control" id='currentpassword' required></td>
                            </tr>    
                            <tr class="text-left">
                              <td>รหัสผ่านใหม่</td>
                              <td><input type="password" class="form-control" id='newpassword' required></td>
                            </tr>     
                            <tr class="text-left">
                              <td>ยืนยันรหัสผ่านใหม่</td>
                              <td><input type="password" class="form-control" id='confirmnewpassword' required></td>
                            </tr>                                                                                                   
                          </tbody>
                        </table>
                      </form>
                      <button type="button" id="submit" class="btn btn-success" @click="changepassword"><i class="icon-key"></i> เปลี่ยนรหัสผ่าน</button>
                      <hr>
                  </div>
              </div>
            </div>                
          </div>
    <Footer/>
  </div>
</div>
</template>
<script>
import $ from 'jquery'
import axios from 'axios'
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Main',
  data() {
    return {
      prefix: [],
      getprofile: [],
      prefix_id_selected: '',
      users_id_q: '',
    }
  },
  components: {
    Topbar,
    Footer
  },
  created() {
    var formData = new FormData();
    formData.append('action', 'getprefix');
    axios({
      method: 'post',
      url: api.register,
      headers: { 'Content-Type': 'application/json' },
      data: formData,
    })
    .then((response) => {
      this.prefix = response.data.result
      // console.log(response.data)
    }, (error) => {
      alert(error)
      // console.log(error.data);
    });

    var accesstoken = localStorage.getItem('accesstoken'); 
    var formDataaccess = new FormData();
    formDataaccess.append('accesstoken', accesstoken);
    axios({
      method: 'post',
      url: api.session,
      headers: { 'Content-Type': 'application/json' },
      data: formDataaccess,
    })
    .then((response) => {
        this.session_status = response.data.status
        this.users_id_q = response.data.users_id
        if ( response.data.status == 'error') {
          window.setTimeout(function(){ 
              window.location="/login";
              // location.reload();
          } ,0)
        } else {
        // getprofile
          if ( response.data.confirm_account === 'false') {
              window.location="/confirm";
          } else {
            var formDatagname = new FormData();
            formDatagname.append('action', 'getprofileupdate');
            formDatagname.append('users_id', response.data.users_id);
            axios({
              method: 'post',
              url: api.getprofile,
              headers: { 'Content-Type': 'application/json' },
              data: formDatagname,
            })
            .then((response) => {
                this.getprofile = response.data.result
                // console.log(response.data.result)
              }
            );
          }
        }
      }
    );    
  },
  methods: { 
    updateaccount () {
        var formDataupdate = new FormData();
            formDataupdate.append('action', 'updateprofile');
            formDataupdate.append('users_idupdate', $('#users_id').val());
            formDataupdate.append('prefixupdate', $('#prefix').val());
            formDataupdate.append('firstnameupdate', $('#firstname').val());
            formDataupdate.append('lastnameupdate', $('#lastname').val());
            formDataupdate.append('addressupdate', $('#address').val());
            formDataupdate.append('emailupdate', $('#email').val());
            formDataupdate.append('telupdate', $('#tel').val());
            axios({
              method: 'post',
              url: api.register,
              headers: { 'Content-Type': 'application/json' },
              data: formDataupdate,
            })
            .then((response) => {
                if ( response.data.status === 'success') {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.msg,
                    showConfirmButton: true,
                    timer: 3000,
                  },window.setTimeout(function(){ 
                      location.reload();
                  } ,2000))
                } else {
                  Swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: response.data.msg,
                      showConfirmButton: true,
                      timer: 10000,
                    },function(){ 
                        
                    })
                  }
              }
            );
    },
    changepassword () {
        var formDataupdate = new FormData();
            formDataupdate.append('action', 'changepassword');
            formDataupdate.append('currentpassword', $('#currentpassword').val());
            formDataupdate.append('newpassword', $('#newpassword').val());
            formDataupdate.append('confirmnewpassword', $('#confirmnewpassword').val());
            formDataupdate.append('users_id', this.users_id_q);
            // console.log(this.users_id_q)

            if ( $('#currentpassword').val() == '') {
              Swal.fire({
                  position: 'center',
                  // icon: 'error',
                  icon: 'error',
                  title: "กรุณากรอกรหัสผ่านเดิม",
                  showConfirmButton: true,
                  timer: 10000,
              })
            }
            else if  ( $('#newpassword').val() == '') {
              Swal.fire({
                  position: 'center',
                  // icon: 'error',
                  icon: 'error',
                  title: "กรุณากรอกรหัสผ่านใหม่",
                  showConfirmButton: true,
                  timer: 10000,
              })
            } 
            else if  ( $('#confirmnewpassword').val() == '') {
              Swal.fire({
                  position: 'center',
                  // icon: 'error',
                  icon: 'error',
                  title: "กรุณายืนยันรหัสผ่าน",
                  showConfirmButton: true,
                  timer: 10000,
              })
            }                         
            else if  ( $('#newpassword').val() !== $('#confirmnewpassword').val() ) {
              Swal.fire({
                  position: 'center',
                  // icon: 'error', 
                  icon: 'error',
                  title: "รหัสผ่านไม่ตรงกัน",
                  showConfirmButton: true,
                  timer: 10000,
              })
            } else {
              axios({
                method: 'post',
                url: api.changepassword,
                headers: { 'Content-Type': 'application/json' },
                data: formDataupdate,
              })
              .then((response) => {
                  if ( response.data.status === 'success') {
                    Swal.fire({
                      position: 'center',
                      // icon: 'success',
                      icon: 'success',
                      title: "เปลี่ยนรหัสผ่านเรียบร้อย",
                      showConfirmButton: true,
                      timer: 3000,
                    },window.setTimeout(function(){ 
                        location.reload();
                    } ,2000))
                  } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "รหัสผ่านเดิมไม่ถูกต้อง กรุณากรอกรหัสผ่านเดิมใหม่อีกครั้ง",
                        showConfirmButton: true,
                        timer: 10000,
                    })
                  }
                }
              );
            }
    }
  }
}
</script>
<style>
.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 45em;
    max-width: 100%;
    padding: 5em;
    padding-top: 5em;
    padding-right: 5em;
    padding-bottom: 5em;
    padding-left: 5em;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
}
</style>