<template>
<div class="main">
  <div>
    <Topbar/>
    <div class="container">
      <article class="card-body mx-auto error">
            <img class="imgresponsive" src="https://oc.schooldee.info/404.png" alt="404">
            <br>
            <a href="/" class="buttonhomepage">กลับสู่หน้าหลัก</a>
      </article><br><br><br>
    </div> 
    <Footer/>
  </div>
</div>
</template>
<script>

import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Error',
  components: {
    Topbar,
    Footer
  }, 
}
</script>
<style>
.error {
    text-align: center;
}
a.buttonhomepage {
  background-color: #ffffff; /* Green */
  border: 2px solid #4CAF50;
  color: #4CAF50;
  padding: 15px 150px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition: 0.3s;
}
.imgresponsive {
  max-width: 100%;
  height: auto;
}

</style>