<template>
<div class="main">
  <div>
    <Topbar/>
        <div class="container">
        <br>
        <article class="card-body mx-auto" style="max-width: 500px;">
          <h4 class="card-title text-center">สร้างบัญชีผู้ใช้งาน</h4>
          <p></p>
            <form @submit.prevent="createaccount"> 
              <div class="form-group">
                <label class="cols-sm-2 control-label">คำนำหน้า</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-users"></i> </span>
                  <select class="form-control" v-model="prefix_id_selected" required>
                      <option disabled value="">เลือกคำนำหน้า</option>
                      <option v-for="data in prefix" :key="data.prefix_id" :value="data.prefix_id"> {{ data.prefix_name}} </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="cols-sm-2 control-label">ชื่อ - นามสกุล</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-user-circle-o fa-2x"></i> </span>
                  <input v-model="firstname" class="form-control" placeholder="ชื่อ" type="text" required>
                  <span class="input-group-text"> <i class="fa fa-user-circle-o fa-2x"></i></span>
                  <input v-model="lastname" class="form-control" placeholder="นามสกุล" type="text" required>
                </div>
              </div>
              <div class="form-group">
                <label class="cols-sm-2 control-label">Email</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-envelope fa-2x"></i> </span>
                  <input v-model="email" class="form-control" placeholder="E-mail" type="email" required>
                </div>
              </div>
              <div class="form-group">
                <label class="cols-sm-2 control-label">เบอร์โทรศัพท์</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-phone fa-2x"></i> </span>
                  <input v-model="tel" class="form-control" placeholder="เบอร์โทรศัพท์" type="text" maxlength="10" required>
                </div>
              </div>
              <div class="form-group">
                <label class="cols-sm-2 control-label">ที่อยู่</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-map-marker fa-2x"></i> </span>
                  <input v-model="address" class="form-control" placeholder="ที่อยู่" type="text" required>
                </div>
              </div>
              <div class="form-group">
                <label class="cols-sm-2 control-label">ชื่อผู้ใช้งาน</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-user fa-2x"></i> </span>
                  <input v-model="username" class="form-control" placeholder="ชื่อผู้ใช้งาน" type="text" required>
                </div>
              </div>
              <div class="form-group">
                <label class="cols-sm-2 control-label">รหัสผ่าน</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-lock fa-2x"></i> </span>
                  <input v-model="password" class="form-control" placeholder="สร้างรหัสผ่าน" type="password" autocomplete="off" required>
                </div>
              </div>
              <div class="form-group">
                <label class="cols-sm-2 control-label">ยืนยันรหัสผ่าน</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-lock fa-2x"></i> </span>
                  <input v-model="confirmpassword" class="form-control" placeholder="ยืนยันรหัสผ่าน" type="password" autocomplete="off" required>
                </div>
              </div>
              <hr>                                   
              <div class="form-group">
                <button type="submit"  class="btn btn-primary btn-block"> สร้างบัญชี  </button>
              </div>                                                                
            </form>
        </article><br><br>
      </div> 
    <Footer/>
  </div>
</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Main',
  data() {
    return {
      prefix: [],
      prefix_id_selected: '',
      lastname: '',
      firstname: '',
      email: '',
      tel: '',
      address: '',
      username: '',
      password: '',
      confirmpassword: '',
    }
  },
  components: {
    Topbar,
    Footer
  },
  created() {
    var formData = new FormData();
    formData.append('action', 'getprefix');
    axios({
      method: 'post',
      url: api.register,
      headers: { 'Content-Type': 'application/json' },
      data: formData,
    })
    .then((response) => {
      this.prefix = response.data.result
      // console.log(response.data)
    }, (error) => {
      alert(error)
      // console.log(error.data);
    });
  },
  methods: { 
    createaccount () {
        let timerInterval
          Swal.fire({
            title: 'กำลังทำรายการ',
            timer: 1500,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              timerInterval = setInterval(() => {
                const content = Swal.getContent()
                if (content) {
                  const b = content.querySelector('b')
                  if (b) {
                    b.textContent = Swal.getTimerLeft()
                  }
                }
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          })
          var formData = new FormData();
              formData.append('prefix_id', this.prefix_id_selected);
              formData.append('lastname', this.lastname);
              formData.append('firstname', this.firstname);
              formData.append('email', this.email);
              formData.append('tel', this.tel);
              formData.append('address', this.address);
              formData.append('username', this.username);
              formData.append('password', this.password);
          if ( this.password !== this.confirmpassword ) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: "รหัสผ่านไม่ตรงกัน",
              showConfirmButton: true,
              timer: 10000,
            })
          } else {
            axios({
              method: 'post',
              url: api.registercheck,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              if ( response.data.status === 'success') {
                // console.log(response.data.accesstoken)
                var formDatagmailregister = new FormData();
                    formDatagmailregister.append('accesstoken', response.data.accesstoken);
                    formDatagmailregister.append('email', this.email);  
                    formDatagmailregister.append('firstname', this.firstname); 
                    formDatagmailregister.append('lastname', this.lastname);          
                axios({
                  method: 'post',
                  url: api.gmailregister,
                  headers: { 'Content-Type': 'application/json' },
                  data: formDatagmailregister,
                })
                .then((res) => {
                    if ( res.data.status === 'success') {
                        Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: "ระบบได้ส่งการยืนยันบัญชีไปที่ <br/>email : "+this.email+" <br/> ของคุณเรียบร้อยแล้ว กรุณายืนยันบัญชี",
                          showConfirmButton: true,
                          timer: 10000,
                        },window.setTimeout(function(){ 
                            window.location="/login";
                        } ,5000))
                    }  else {
                            Swal.fire({
                              position: 'center',
                              icon: 'error',
                              title: "ไม่สามารถส่ง email ได้ในขณะนี้",
                              showConfirmButton: true,
                              timer: 10000,
                            },window.setTimeout(function(){ 
                                // location.reload();
                            } ,5000))                      
                    }
                })
              } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: response.data.message,
                    showConfirmButton: true,
                    timer: 10000,
                  })
              }
            }, (error) => {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: true,
                  timer: 10000
                })
            });            
          }

    }, 
  }
}
</script>
<style>
.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 45em;
    max-width: 100%;
    padding: 5em;
    padding-top: 5em;
    padding-right: 5em;
    padding-bottom: 5em;
    padding-left: 5em;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
}
select.form-control{
      border-radius: 50px;
      background-size: cover;
      width: 100%;
      height: auto;
}
</style>