<template>
<div class="main">
  <div>
    <Topbar/>
        <div class="container">
        <br>
        <article class="card-body mx-auto" style="max-width: 500px;">
          <h4 class="card-title text-center">ลืมรหัสผ่าน</h4>
          <p></p>
            <form @submit.prevent="forgotpassword"> 
              <div class="form-group">
                <label class="cols-sm-2 control-label">Email</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-envelope fa-2x"></i> </span>
                  <input v-model="email" class="form-control" placeholder="E-mail" type="email" required>
                </div>
              </div>
              <hr>                                   
              <div class="form-group">
                <button type="submit"  class="btn btn-primary btn-block"> ขอรับรหัสผ่าน </button>
              </div>                                                                
            </form>
        </article><br><br>
      </div> 
    <Footer/>
  </div>
</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Main',
  data() {
    return {
      email: '',
    }
  },
  components: {
    Topbar,
    Footer
  },
  methods: { 
    forgotpassword () {
      let timerInterval
        Swal.fire({
          title: 'กำลังทำรายการ',
          timer: 1500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
              const content = Swal.getContent()
              if (content) {
                const b = content.querySelector('b')
                if (b) {
                  b.textContent = Swal.getTimerLeft()
                }
              }
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })
        var formData = new FormData();
            formData.append('action', 'forgotpassword');
            formData.append('email', this.email);
            // alert(this.email)
        axios({
          method: 'post',
          url: api.reset,
          headers: { 'Content-type': 'application/json' },
          data: formData,
        })
        .then((response) => {
          if ( response.data.status === 'success') {
            this.tokenemail = response.data.accesstoken
            var formData = new FormData();
                  formData.append('accesstoken', response.data.accesstoken);
                  formData.append('email', this.email);
                  axios({
                    method: 'post',
                    url: api.gmailreset,
                    headers: { 'Content-type': 'application/json' },
                    data: formData,
                  })
                  .then((res) => {
                    if ( res.data.status === 'success') {
                        Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: "ระบบได้ส่งการรีเซ็ตรหัสผ่านไปที่ email ของคุณเรียบร้อยแล้ว กรุณาตรวจสอบ email",
                          showConfirmButton: true,
                          timer: 5000,
                        },window.setTimeout(function(){ 
                            location.reload();
                        } ,5000))
                    } else {
                        Swal.fire({
                          position: 'center',
                          icon: 'error',
                          title: "ไม่สามารถส่ง email ได้ในขณะนี้",
                          showConfirmButton: true,
                          timer: 10000,
                        },window.setTimeout(function(){ 
                            location.reload();
                        } ,5000))                      
                    }
                  })
          } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "ไม่พบ email นี้ในระบบ",
                showConfirmButton: true,
                timer: 10000,
              },function(){ 
                  
              })
            }
        }, (error) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: error,
              showConfirmButton: true,
              timer: 10000
            })
        });
    }
  }
}
</script>
<style>
.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 45em;
    max-width: 100%;
    padding: 5em;
    padding-top: 5em;
    padding-right: 5em;
    padding-bottom: 5em;
    padding-left: 5em;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
}
select.form-control{
      border-radius: 50px;
      background-size: cover;
      width: 100%;
      height: auto;
}
</style>