<template>
  <div class="no-child-div">
    <div class="container" data-turbolinks="false" >
      <ul class="categories-list">
        <li v-for="data in category" :key="data.course_category_id">
          <a :href="`courses?c=${data.course_category_id}`">
            <span> {{ data.name_th }}</span>
          </a>             
        </li>                     
        <div class="clearfix"></div>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import api from '@/urlapi.js'
export default {
  name: "Category",
  data (){
    return {
      category: [],
    }
  },
  created() {
      this.getcategory();
  }, 
  mounted() {
    
  },
  props: {
    list: String,
  },
  methods: {
      getcategory() {
        var formDataCategory = new FormData();
          formDataCategory.append('action', 'getcategory');
            axios({
              method: 'post',
              url: api.getcategory,
              headers: { 'Content-Type': 'application/json' },
              data: formDataCategory,
            })
            .then((res) => {
              this.category = res.data.result
            })
      },    
  },

};
</script>

<style>
ul.categories-list {
    list-style-type: none;
    margin-top: -145px;
    z-index: 9999;
    padding: 20px 15px;
    /* position: relative; */
    border: 0;
    z-index: 1;
    align-items: center;
    justify-items: center;
    
    /* background-color: #5d4a8e; */
    background-color: #3c2e5e;
    border-radius: 10px;
    box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
    width: 100%;
}
</style>