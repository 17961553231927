<template>
  <div class="main">
    <Topbar/>
        <div class="container">           
            <div class="row"> 
                <div class="container"> 
                    <div class="col-md-12"> 
                        <h3>ตรวจสอบเกียรติบัตร</h3>
                        <div class="row">
                          <div class="col-md-4 col-lg-4"></div>
                          <div class="col-md-4 col-lg-4">
                            <div class="form-group text-center">
                              <h4>กรอกรหัสอ้างอิง 8 หลัก</h4>
                              <input type="text" class="form-control" maxlength="8" required autofocus v-model="ref" onkeyup="this.value = this.value.toUpperCase();" autocomplete="off"><br>
                              <button type="button" class="btn btn-success" @click="certificatecheck">ตรวจสอบเกียรติบัตร</button>
                            </div>
                          </div>
                          <div class="col-md-4 col-lg-4"></div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center"><i class="icon-reorder"></i></th>
                                    <th scope="col" class="text-center">รหัสอ้างอิง</th>
                                    <th scope="col" class="text-center">ชื่อคอร์ส</th>
                                    <th scope="col" class="text-center">สถานะ</th>
                                    <th scope="col" class="text-center">วันที่</th>
                                    <th scope="col" class="text-center"><i class="icon-reorder"></i></th>
                                    <!-- <th scope="col" class="text-center">ดาวน์โหลด</th> -->
                                </tr>
                            </thead>
                              <tbody>
                                  <tr v-if="status =='success'">
                                    <td class="text-center">1</td>
                                    <td class="text-center">{{ refer }}</td>
                                    <td class="text-center">{{ course_name }}</td>
                                    <td class="text-center">สำเร็จ</td>
                                    <td class="text-center">{{ datesuccess }}</td>
                                    <td class="text-center"><a :href="`https://mli.pcru.ac.th/api/pdf/${refer}.pdf`"  class="btn btn-info detail-cert" role="button">ดาวน์โหลดเกียรติบัตร</a></td>
                                  </tr>
                                  <tr v-else-if="status =='error'">
                                    <td colspan="6" class="text-center">ไม่พบข้อมูล</td>
                                  </tr>
                              </tbody>

                        </table><br>
                    </div>
                </div>               
            </div>
        </div>    
    <Footer/>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Checkcertificate',
  data() {
    return {
      status: '',
      refer: '',
      datesuccess: '',
      course_name: '',
      ref: '',
    }
  },
  components: {
    Topbar,
    Footer
  },

  methods: {
      certificatecheck() {
        var formData = new FormData();
          formData.append('action', 'checkcertificate');
          formData.append('ref', this.ref);
            axios({
              method: 'post',
              url: api.checkcertificate,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              this.status = response.data.status
              this.refer = response.data.ref
              this.datesuccess = response.data.datesuccess
              this.course_name = response.data.course_name
            });
          this.ref = "";
      },
      downloadcertificate () {
        let timerInterval
        Swal.fire({
            icon: 'success',
            title: 'กำลังประมวลผล',
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
                const content = Swal.getContent()
                if (content) {
                const b = content.querySelector('b')
                if (b) {
                    b.textContent = Swal.getTimerLeft()
                }
                }
            }, 100)
            },
            willClose: () => {
            clearInterval(timerInterval)
            }
        })
        axios({
            url: 'https://mli.pcru.ac.th/api/pdf/'+this.refer+'.pdf',
            method: 'GET',
            responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'certificate.pdf');
                document.body.appendChild(link);
                link.click();
            });
     
      }
    },
    mounted() {
      setInterval(
        document.onreadystatechange = () => {
          if (document.readyState == "complete") { 
          this.isloaded = true;
          } 
        }
      ,2000);
    },
}
</script>
<style>
select.form-control{
  border-radius: 0px;
  background-size: cover;
  width: 100%;
  height: auto;
}
.main .title {
    font-size: 22px;
    font-weight: 300;
    margin: 7px 0;
    color: #000;
}
button.detail-cert{
        width: 100%;
        background-color: #2d1961; /* Green */
        border: 2px solid #2d1961;
        color: #ffffff;
        /* padding: 15px 150px; */
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        transition: 0.3s;
}
</style>