<template>
<div class="main">
    <div>
      <TopbarVideo/>
        <!-- preloader -->
        <div class="page-loader" v-if="!isloaded">
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
        </div>
        <!-- content -->
        <div>
          <div v-if="courseapprove === 'unapprove'">
              <div class="container">
                  <br>
                  <article class="card-body mx-auto">
                      <div class="errormargin">
                          <h2 class="card-title text-center">{{ message }}</h2>
                      </div>
                  </article>
              </div>  
          </div>
          <div v-if="courseapprove === 'approve'">
            <div>
              <!-- <div class="customcontent-v"></div> -->
              <div class="container">
                <!-- <div class="card bg-light"> -->
                  <article class="card-body mx-auto">
                    <div>
                      <div v-if="leaning_path_status == 'error'">
                        <div class="container">
                            <br>
                            <article class="card-body mx-auto">
                                <div class="errormargin">
                                    <h2 class="card-title text-center">ไม่พบเนื้อหา</h2>
                                    <br><br>
                                </div>
                                
                            </article>
                        </div>
                      </div>
                      <div v-else>
                        <h3>{{ course_name }} </h3>
                        <h5>{{ leaning_path_name }}</h5> 
                        <vue-plyr ref="player">
                          <video
                            controls
                            crossorigin
                            playsinline
                            @playing="nowPlaying"
                            @timeupdate="timeupdate"
                            @pause="pause"
                            :src="`https://mli.pcru.ac.th/adminapi/videos/${qualityvideo}`"
                          >
                            <source size="480" :src="`https://mli.pcru.ac.th/adminapi/videos/${vp480}`" type="video/mp4" />
                            <source size="720" :src="`https://mli.pcru.ac.th/adminapi/videos/${vp720}`" type="video/mp4" />
                            <source size="1080" :src="`https://mli.pcru.ac.th/adminapi/videos/${vp1080}`" type="video/mp4" />
                          </video>
                        </vue-plyr>
                        <br>
                      </div>
                    </div>
                    <div v-if="documentstatus === 'success'">
                      เอกสารประกอบการเรียน : <a :href="`https://mli.pcru.ac.th/adminapi/files/document/${document}`" target="_blank">ดาวน์โหลด</a>
                    </div>
 
                  </article>
                  
              </div>
            </div>
          </div>
        </div>  
      <Footer/>
    </div>
</div>
</template>
<script>
import TopbarVideo from '@/components/TopbarVideo.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
import axios from 'axios';
export default {
  name: "Video",
  components: {
    TopbarVideo,
    Footer,
  },
  data() {
    return {
      isloaded: false,
      options: { quality: { default: "1080p" } },
      currenttime: 0,
      duration: '0',
      progressbar: '0',
      player: null,
      id : this.$route.params.id,
      session_status: '',    
      accesstoken: '',
      courseapprove: 'approve',
      message: '',
      users_id_q: '',
      //  
      course_id: '',
      leaning_path_name: '',
      course_name: '',
      leaning_path_status: '',
      percentcourse: '',
      qualityvideo: '',
      qualitysize: '',
      vp1080: '',
      vp720: '',
      vp480: '',
      document: '',
      documentstatus: '',
      
    };
  },
  created() {
    // get id accesstoken
    var accesstoken = localStorage.getItem('accesstoken');
      if (accesstoken == null) {
          window.location.href = "/login"; 
      } else {
          this.accesstoken = localStorage.getItem('accesstoken');
          // console.log(accesstoken)
          var formDataaccesstoken = new FormData();
              formDataaccesstoken.append('accesstoken', accesstoken);
              axios({
                  method: 'post',
                  url: api.session,
                  headers: { 'Content-Type': 'application/json' },
                  data: formDataaccesstoken,
              })
              .then((response) => {
                  this.session_status = response.data.status
                  this.users_id_q = response.data.users_id
                  // console.log(response.data.status)
                  if ( response.data.status == 'success') {
                    if ( response.data.confirm_account === 'false') {
                            window.location="/confirm";
                        } else {
                    var formDataCApprove = new FormData();
                        formDataCApprove.append('action', 'checkapprovevideo');
                        formDataCApprove.append('course_id', this.$route.params.id);
                        formDataCApprove.append('users_id', response.data.users_id);
                        axios({
                          method: 'post',
                          url: api.courses,
                          headers: { 'Content-Type': 'application/json' },
                          data: formDataCApprove,
                          })
                          .then((response) => {
                            this.courseapprove = response.data.status
                            this.message = response.data.message
                            // console.log(response.data.status)
                          })
                    }
                  }
              });
      }
      var formDatagetnamelearningpath = new FormData();
          formDatagetnamelearningpath.append('action', 'getnamelearningpath');
          formDatagetnamelearningpath.append('learningpathid', this.$route.query.q );
          formDatagetnamelearningpath.append('course_id', this.$route.params.id );
          axios({
              method: 'POST',
              url: api.getnamelearningpath,
              headers:  { 
                          'Content-Type': 'application/json',
                          'Access-Control-Allow-Headers': '*',
                          'Access-Control-Allow-Origin': '*',
                          'Access-Control-Allow-Methods' : 'GET, POST',
                        },
              data: formDatagetnamelearningpath,
          })
          .then((response) => {
            this.leaning_path_name = response.data.leaning_path_name
            this.leaning_path_status = response.data.status
            this.course_name = response.data.course_name
            this.percentcourse = response.data.percentcourse
            if ( response.data.l1080 === 'nullvalue') {
                if ( response.data.l720 === 'nullvalue') {
                  this.qualityvideo = response.data.l480
                  this.qualitysize = '480'
                  // 480 All
                  this.vp1080 = response.data.l480+"?1080"
                  this.vp720 = response.data.l480+"?720"
                  this.vp480 = response.data.l480+"?480"
                } else {
                  this.qualityvideo = response.data.l720
                  this.qualitysize = '720'
                  // 720 & 480
                  this.vp1080 = response.data.l720+"?1080"
                  this.vp720 = response.data.l720+"?720"
                  this.vp480 = response.data.l480+"?480"
                }
            } else {
                this.qualityvideo = response.data.l1080
                this.qualitysize = '1080'
                // 1080 & 720 & 480                  
                this.vp1080 = response.data.l1080+"?1080"
                this.vp720 = response.data.l720+"?720"
                this.vp480 = response.data.l480+"?480"
            }
            localStorage.setItem('plyr', '{"quality":'+this.qualitysize+',"volume":1,"muted":false}');
          })
          // documentfile
          var formDataDocument = new FormData();
              formDataDocument.append('action', 'document');
              formDataDocument.append('learningpathid', this.$route.query.q );
              axios({
                  method: 'post',
                  url: api.document,
                  headers: { 'Content-Type': 'application/json' },
                  data: formDataDocument,
              })
              .then((response) => {
                  this.documentstatus = response.data.status 
                  this.document = response.data.document 
              });
    },
  mounted() {
    this.player = this.$refs.player.player;
    this.v = this.video_1080p
    setInterval(
      document.onreadystatechange = () => {
        if (document.readyState == "complete") { 
          this.isloaded = true;
        } 
      }
    ,3000);
  },

  methods: {
    timeupdate() {
      
      this.currenttime = parseInt(this.player.currentTime);
      this.duration = parseInt(this.player.duration);
      
      var t_currenttime = this.player.currentTime;
      var t_duration = parseInt(this.player.duration);

      var per0 = (t_duration - 10 ) * 0 / 100;
      var per0end = (t_duration - 10 ) * 0 / 100 + 0.27;
      var per20 = (t_duration - 10 ) * 20 / 100;
      var per20end = (t_duration - 10 ) * 20 / 100 + 0.27;
      var per40 = (t_duration - 10 ) * 40 / 100;
      var per40end = (t_duration - 10 ) * 40 / 100 + 0.27;
      var per60 = (t_duration - 10 ) * 60 / 100;
      var per60end = (t_duration - 10 ) * 60 / 100 + 0.27;
      var per80 = (t_duration - 10 ) * 80 / 100;
      var per80end = (t_duration - 10 ) * 80 / 100 + 0.27; 
      var per100 = (t_duration - 10 )* 100 / 100;
      var per100end = (t_duration - 10) * 100 / 100 + 0.27; 
      
      this.progressbar = parseInt(this.currenttime * 100 / per80);

      var action_update = "updatestate";
      var course_id_update = this.$route.params.id;
      var users_id_update = this.users_id_q;
      var learningpathid_update = this.$route.query.q;
      var currenttime_update = this.player.currentTime;
      var duration_update = this.player.duration;

      if ( t_currenttime >= per0 && t_currenttime <= per0end ) {
          // console.log("start"); 
      } else
      if ( t_currenttime >= per20 && t_currenttime <= per20end ) {
          this.savepercentstate(action_update,course_id_update,users_id_update,learningpathid_update,currenttime_update,duration_update)
      } else 
      if ( t_currenttime >= per40 && t_currenttime <= per40end ) {
          this.savepercentstate(action_update,course_id_update,users_id_update,learningpathid_update,currenttime_update,duration_update) 
      } else
      if ( t_currenttime >= per60 && t_currenttime <= per60end ) {
          this.savepercentstate(action_update,course_id_update,users_id_update,learningpathid_update,currenttime_update,duration_update) 
      } else 
      if ( t_currenttime >= per80 && t_currenttime <= per80end ) {
          this.savepercentstate(action_update,course_id_update,users_id_update,learningpathid_update,currenttime_update,duration_update) 
      } else
      if ( t_currenttime >= per100 && t_currenttime <= per100end ) {
          this.savepercentstate(action_update,course_id_update,users_id_update,learningpathid_update,currenttime_update,duration_update) 
      }
      
    },
    savepercentstate (action_update,course_id_update,users_id_update,learningpathid_update,currenttime_update,duration_update) {
      // alert(action_update+"-"+course_id_update+"-"+users_id_update+"-"+learningpathid_update+"-"+currenttime_update+"-"+duration_update)
      var formDataupdatepercent = new FormData();
          formDataupdatepercent.append('action', action_update);
          formDataupdatepercent.append('course_id', course_id_update);
          formDataupdatepercent.append('users_id', users_id_update);
          formDataupdatepercent.append('learningpathid', learningpathid_update);
          formDataupdatepercent.append('currenttime', currenttime_update);
          formDataupdatepercent.append('durationtime', duration_update);
          axios({
            method: 'post',
            url: api.learningstate,
            headers: { 'Content-Type': 'application/json' },
            data: formDataupdatepercent,
            })
          .then((res) => { 
            this.resmsg = res.data.msg
            // console.log(this.resmsg)
          });
    },
    nowPlaying() {
      var formDatasetstate = new FormData();
          formDatasetstate.append('action', 'setlearningstate');
          formDatasetstate.append('course_id', this.$route.params.id);
          formDatasetstate.append('users_id', this.users_id_q);
          formDatasetstate.append('learningpathid', this.$route.query.q);
          formDatasetstate.append('currenttime', this.player.currentTime);
          formDatasetstate.append('durationtime', this.player.duration);
          axios({
            method: 'post',
            url: api.learningstate,
            headers: { 'Content-Type': 'application/json' },
            data: formDatasetstate,
            })
          .then((res) => { 
            this.resmsg = res.data.msg
          });
    },
    pause(){
      var formDataupdatelearningstate = new FormData();
          formDataupdatelearningstate.append('action', 'updatestate');
          formDataupdatelearningstate.append('course_id', this.$route.params.id);
          formDataupdatelearningstate.append('users_id', this.users_id_q);
          formDataupdatelearningstate.append('learningpathid', this.$route.query.q);
          formDataupdatelearningstate.append('currenttime', this.player.currentTime);
          formDataupdatelearningstate.append('durationtime', this.player.duration);
          axios({
            method: 'post',
            url: api.learningstate,
            headers: { 'Content-Type': 'application/json' },
            data: formDataupdatelearningstate,
            })
          .then((res) => { 
            this.resmsg = res.data.msg
          });
    }
  },
};
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  .errormargin {
      margin-top: 200px;
      margin-bottom: 300px;
  }
  .errormargin-v {
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .customcontent-v {
      margin-top: 100px;
      margin-bottom: 100px;
  }
  
/* loader */
  .page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #333;
    z-index: 999;
  }

  .cube{
    width: 40px;
    height: 40px;
    margin-right: 10px;

  }

  .cube:nth-child(1) {
    background-color: #8CC271;
  }
  .cube:nth-child(2) {
    background-color: #69BEEB;
  }
  .cube:nth-child(3) {
      background-color: #F5AA39;
  }
  .cube:nth-child(4) {
      background-color: #E9643B;
  }
  .cube:first-child {
    animation: left 1s infinite;
  }
  .cube:last-child {
    animation: right 1s infinite .5s;
  }
  
  @keyframes left {
    40% {
      transform: translateX(-60px);
    }
    50% {
      transform: translateX(0);      
    }
  }
  @keyframes right {
    40% {
      transform: translateX(60px);
    }
    50% {
      transform: translateX(0);
    }
  }
</style>
