export default {
    register : "https://mli.pcru.ac.th/api/register.php",
    registercheck : "https://mli.pcru.ac.th/api/register_check.php",
    emailcheck : "https://mli.pcru.ac.th/api/email_check.php",
    confirmcheck : "https://mli.pcru.ac.th/api/confirm_check.php",
    uploadpayment : "https://mli.pcru.ac.th/api/uploadpayment.php",
    courses : "https://mli.pcru.ac.th/api/courses.php",
    teacher : "https://mli.pcru.ac.th/api/teacher.php",
    coursepreview : "https://mli.pcru.ac.th/api/coursepreview.php",
    login : "https://mli.pcru.ac.th/api/login.php",
    otp : "https://mli.pcru.ac.th/api/otp.php",
    reset: "https://mli.pcru.ac.th/api/reset.php",
    changepassword: "https://mli.pcru.ac.th/api/changepassword.php",
    changeconfirm: "https://mli.pcru.ac.th/api/changeconfirm.php",
    gmailreset : "https://mli.pcru.ac.th/adminapi/gmail_reset.php",
    gmailregister: "https://mli.pcru.ac.th/adminapi/gmail_register.php",
    purchasesgmail : "https://mli.pcru.ac.th/adminapi/gmail_purchase.php",
    gmailuploadpayment : "https://mli.pcru.ac.th/adminapi/gmail_uploadpayment.php",
    session : "https://mli.pcru.ac.th/api/session_check.php",
    resetcheck: "https://mli.pcru.ac.th/api/reset_check.php",
    tprof : "https://mli.pcru.ac.th/api/topbar.php", 
    purchases : "https://mli.pcru.ac.th/api/purchases.php",
    getprofile : "https://mli.pcru.ac.th/api/getprofile.php",
    getemail : "https://mli.pcru.ac.th/api/getemail.php",
    getlearning : "https://mli.pcru.ac.th/api/getlearning.php",
    getcategory : "https://mli.pcru.ac.th/api/getcategory.php",
    getcname : "https://mli.pcru.ac.th/api/getcname.php",
    getnamelearningpath : "https://mli.pcru.ac.th/api/getnamelearningpath.php",
    learningstate : "https://mli.pcru.ac.th/api/learningstate.php",
    example : "https://mli.pcru.ac.th/api/exampleapi.php",
    checkExam : "https://mli.pcru.ac.th/api/checkquiz.php",
    checkquiz : "https://mli.pcru.ac.th/api/checkquiz.php",
    mycourse : "https://mli.pcru.ac.th/api/mycourse.php",
    mycertificate : "https://mli.pcru.ac.th/api/mycertificate.php",
    gencert : "https://mli.pcru.ac.th/api/GenCertificate.php",
    score : "https://mli.pcru.ac.th/api/score.php",
    expretest : "https://mli.pcru.ac.th/api/expretest.php",
    exposttest : "https://mli.pcru.ac.th/api/exposttest.php",
    progress: "https://mli.pcru.ac.th/api/progress.php",
    test : "https://mli.pcru.ac.th/api/test.php",
    imageUrl: "https://mli.pcru.ac.th/logo-tbar.php",
    checkcertificate: "https://mli.pcru.ac.th/api/checkcertificate.php",
    history: "https://mli.pcru.ac.th/api/history.php",
    checkdateregistrator: "https://mli.pcru.ac.th/api/checkdateregistrator.php",
    pay: "https://mli.pcru.ac.th/api/pay.php",
    document: "https://mli.pcru.ac.th/api/document.php",
    
}