<template>
  <div>
    <nav id="navbar" class="navbar filter-bar navbar-fixed-top filled">
      <div class="container">
        <div class="notification">
          <div id="notif-message" class="notif-message" style="display: none" notice-type="success"></div>
        </div>
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
            <span class="icon-bar"></span> <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div data-turbolinks="false">
            <a href="/" class="navbar-brand">
              <div class="logo">
                <img src="http://limooc.pcru.ac.th/logo.png" width="60" height="60" class="logoresponsive"/>
              </div>
              <p>
                <font class="logo-title-top">สถาบันภาษา มหาวิทยาลัยราชภัฏเพชรบูรณ์</font> <br />
                <font class="logo-title-down">Language Institute Phetchabun Rajabhat University</font>
              </p>
            </a>
          </div>
        </div>
        <div class="collapse navbar-collapse navbar-ex1-collapse">
          <ul class="nav navbar-nav navbar-new-categories navbar-right" data-turbolinks="false">
            
            <li class="blog">
              <a href="/courses">
                <i class="fa fa-caret-square-o-right" aria-hidden="true"></i>
                <p>คอร์สเรียน</p>
              </a>
            </li>
            <!-- <li class="blog">
              <a href="#">
                <i class="fa fa-book" aria-hidden="true"></i>
                <p>การใช้งาน</p>
              </a>
            </li> -->
            <!-- <li class="blog">
              <a href="#">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
                <p>คำถามที่พบบ่อย</p>
              </a>
            </li> -->
            <li class="blog">
              <a href="/checkcertificate">
                <i class="fa fa-clone" aria-hidden="true"></i>
                <p>ตรวจสอบเกียรติบัตร</p>
              </a>
            </li>
            <!-- <li class="blog">
              <a href="#">
                <i class="fa fa-users" aria-hidden="true"></i>
                <p>นิสิตและบุคลากร</p>
              </a>
            </li> -->
            <li class="dropdown login" data-turbolinks="false" v-if="session_status == 'success'"> 
              <a href="" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"> 
                <i class="fa fa-user-circle-o"></i> 
                <!-- <p>Pongsuwat <span class="caret"></span></p>  -->
                <p v-for="data in getprofile" :key="data.users_id">{{ data.firstname }}</p>
              </a> 
              <ul class="dropdown-menu dropdown-menu-navbar"> 
                <li data-turbolinks="false"> 
                    <a href="/mycourse"> 
                      <i class="icon-shopping-cart"></i> 
                      <p>คอร์สเรียนของฉัน</p> 
                    </a> 
                </li>
                <li data-turbolinks="false"> 
                    <a href="/mypayment"> 
                      <i class="icon-tags"></i>
                      <p>แจ้งชำระเงิน</p> 
                    </a> 
                </li>  
                <li data-turbolinks="false"> 
                    <a href="/mycertificate"> 
                      <i class="fa fa-certificate"></i> 
                      <p>เกียรติบัตรของฉัน</p> 
                    </a> 
                </li> 
                <li data-turbolinks="false"> 
                  <a href="/profile"> 
                    <i class="fa icon-user"></i> 
                    <p>ตั้งค่าโปรไฟล์</p> 
                  </a> 
                </li>
                <li data-turbolinks="false"> 
                  <a href="/history"> 
                    <i class="fa fa-history"></i> 
                    <p>ประวัติการเข้าสู่ระบบ</p> 
                  </a> 
                </li>  
                <li data-turbolinks="false"> 
                  <a href="/logout" > 
                    <i class="icon-remove-circle"></i> 
                    <p >ออกจากระบบ</p> 
                  </a> 
                </li> 
              </ul> 
            </li>
            <li v-else>
              <a href="/login">
                <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                <p> เข้าสู่ระบบ </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import axios from 'axios';
import api from '@/urlapi.js'
export default {
  data() {
    return {
      session_status: '', 
      getprofile: [], 
      confirmaccount: '',
    }
  },
  created() {
    var accesstoken = localStorage.getItem('accesstoken'); 
    var formData = new FormData();
    formData.append('accesstoken', accesstoken);
    axios({
      method: 'post',
      url: api.tprof,
      headers: { 'Content-Type': 'application/json' },
      data: formData,
    })
    .then((response) => {
        this.session_status = response.data.status
        if ( response.data.status === 'success') {
          // getprofile
          var formDatagname = new FormData();
          formDatagname.append('action', 'getprofile');
          formDatagname.append('users_id', response.data.users_id);
          axios({
            method: 'post',
            url: api.getprofile,
            headers: { 'Content-Type': 'application/json' },
            data: formDatagname,
          })
          .then((response) => {
              this.getprofile = response.data.result
              this.confirmaccount = response.data.confirm_account
            }
          );
        } else {
          window.location="/login";
        }

      }
    );
  },
};
</script>
<style>
.form-search {
  margin-top: 10px !important;
}
.logo-title-top {
  font-size: 10px;
  color: #1f2ef6;
  font-weight: bold;
}
.logo-title-down {
  font-size: 8px;
  color: #c35900;
  font-weight: bold;
}
.filter-bar .logo, .dashboard .logo {
    border-radius: 50%;
    border: 0px solid rgb(0, 0, 0);
    display: block;
    height: auto;
    width: 60px;
    float: left;
    overflow: hidden;
}
.filter-bar .logo img, .dashboard .logo img {
    width: 60px;
    height: 60px;
}
.logoresponsive {
  width: 100%;
  height: auto;
}
</style>