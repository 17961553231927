<template>
<div class="main">
    <div>
    <Topbar/>
    <div v-if="session_status === 'error'">
        <div class="container">
            <br>
            <article class="card-body mx-auto">
                <div class="errormargin">
                    <!-- <h1 class="card-title text-center">404</h1>
                    <h2 class="card-title text-center">ERROR! PAGE NOT FOUND.</h2> -->
                    <p></p>
                </div>
            </article>
        </div>
    </div>
    <div v-else-if="session_status === 'success'">
        <div v-if="checkregister === 'success'">
            <div v-if="checkfreecourse === 'payment'">
                <div class="container" v-if="checkuploadpayment === 'success'">           
                    <div class="row">
                        <div class="container">
                            <div class="col-md-3"></div>
                            <div class="col-md-6"><h3 class="text-center">แจ้งชำระเงิน</h3><br><br>
                                <div v-for="data in courses" :key="data.course_id">
                                <form id="formall">
                                    <div class="form-group">
                                        <label class="cols-sm-2 control-label">ชื่อคอร์ส</label>
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fa fa-book fa-2x"></i> </span>
                                        <input class="form-control" :value="data.course_name" type="text" disabled>
                                        <input class="form-control" id="course_shoping_id" :value="data.course_shoping_id" type="hidden">
                                        <input class="form-control" id="users_id" :value="users_id_q" type="hidden">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="cols-sm-2 control-label"> รหัส Order</label>
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fa fa-shopping-cart fa-2x"></i> </span>
                                        <input class="form-control" :value="data.order_id" type="text" disabled>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="cols-sm-2 control-label">ธนาคาร</label>
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fa fa-university fa-2x"></i> </span>
                                        <input class="form-control" id="bank" placeholder="ชื่อธนาคารที่โอน" type="email" required autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="cols-sm-2 control-label">รายละเอียด</label>
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fa fa-info-circle fa-2x"></i> </span>
                                        <input class="form-control" id="detail" placeholder="รายละเอียด" type="text" maxlength="200" autocomplete="off"> 
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="cols-sm-2 control-label">วันที่โอนเงิน</label>
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fa fa-calendar fa-2x"></i> </span>
                                        <input type="date" id="date" class="form-control" required>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="cols-sm-2 control-label">เวลาที่โอนเงิน</label>
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fa fa-clock-o fa-2x"></i> </span>
                                        <input type="time" id="time" class="form-control" required>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="cols-sm-2 control-label">อัพโหลดรูปภาพ (.jpg)</label>
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="icon-picture fa-2x"></i> </span>
                                        <input type="file" id="imgpayment" class="form-control" accept="image/*" required>
                                        </div>
                                    </div>
                                    <hr>                                   
                                    <div class="form-group text-center">
                                        <button type="button" id="submit" class="btn btn-success btn-submitpayment" @click="payment"><i class="icon-tag"></i> แจ้งชำระเงิน</button>
                                    </div>                                                                
                                </form>
                                </div><br><br>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                </div>
                <div class="container" v-if="checkuploadpayment === 'error'">    
                    <br>
                    <article class="card-body mx-auto">
                            <div class="form-group text-center" v-for="datapayment in courses" :key="datapayment.course_id">
                                <h4>คอร์สเรียน : {{ datapayment.course_name }} </h4>
                                <h4>รหัส Order : {{ datapayment.order_id }} </h4>
                                <div v-if="datapayment.price == '0'">
                                    <h4 style="color: #4CAF50;" class="card-title text-center">คอร์สเรียนฟรี</h4>
                                    <br><br><br><br><br><br><br><br><br>
                                </div>
                                <div v-else>
                                    <h3 style="color: #4CAF50;" class="card-title text-center">แจ้งการชำระเงินไปแล้ว</h3><br>
                                    <h4 style="color: #4CAF50;" class="card-title text-center">หลักฐานการชำระเงิน</h4>
                                    <div class="text-center">
                                        <img :src="imgpayment" width="400px" height="auto">
                                    </div>
                                </div>
                            </div>

                            <p></p>
                    </article><br>
                </div>            
            </div>
            <div v-else>
                    <br>
                    <article class="card-body mx-auto">
                            <div class="form-group text-center" v-for="datapayment in courses" :key="datapayment.course_id">
                                <h4>คอร์สเรียน : {{ datapayment.course_name }} </h4>
                                <h4>รหัส Order : {{ datapayment.order_id }} </h4>
                                <h4 style="color: #4CAF50;" class="card-title text-center">คอร์สเรียนฟรี</h4>
                                    <br><br><br><br><br><br><br><br><br>
                            </div>
                            <p></p>
                    </article><br>
            </div>
        </div>
        <div v-if="checkregister === 'error'">
        <div class="container">
            <br>
            <article class="card-body mx-auto">
                <div class="errormargin">
                    <h3 class="card-title text-center">กรุณาลงทะเบียนเรียนก่อน</h3> 
                    <p></p>
                </div>
            </article>
        </div>
        </div>
        <div>
        </div>     
    </div>
    <Footer/>
    </div>
</div>
</template>
<script>
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
    name: 'Uploadpayment',
    data() {
        return {
            id : this.$route.params.id,
            session_status: '',
            checkuploadpayment: '',
            checkfreecourse: '',
            checkregister: '',
            courses: [],
            users_id_q : '',
            imgpayment : '',
        }
    },
    components: {
        Topbar,
        Footer
    },
    created() {
        // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    this.users_id_q = response.data.users_id
                    if ( response.data.status == 'error') {
                      window.setTimeout(function(){ 
                          window.location="/login";
                          // location.reload();
                      } ,0)
                    } else {
                        if ( response.data.confirm_account === 'false') {
                            window.location="/confirm";
                        } else {
                        // getnamecourses
                        var formData = new FormData();
                            formData.append('action', 'getcourseandshopingcid');
                            formData.append('id', this.$route.params.id);
                            formData.append('users_id', this.users_id_q);
                            axios({
                                method: 'post',
                                url: api.courses,
                                headers: { 'Content-Type': 'application/json' },
                                data: formData,
                            })
                            .then((response) => {
                                this.courses = response.data.result
                            });
                         // getuploadpayment yes or no
                        var formDatacheckuploadpayment = new FormData();
                            formDatacheckuploadpayment.append('action', 'checkuploadpayment');
                            formDatacheckuploadpayment.append('course_id', this.$route.params.id);
                            formDatacheckuploadpayment.append('users_id', this.users_id_q);
                            axios({
                                method: 'post',
                                url: api.uploadpayment,
                                headers: { 'Content-Type': 'application/json' },
                                data: formDatacheckuploadpayment,
                            })
                            .then((response) => {
                                this.checkuploadpayment = response.data.status
                                this.checkfreecourse = response.data.type
                                this.imgpayment = response.data.img
                            });
                        //  get courese register
                        var formDatacheckregister = new FormData();
                            formDatacheckregister.append('action', 'checkregister');
                            formDatacheckregister.append('course_id', this.$route.params.id);
                            formDatacheckregister.append('users_id', this.users_id_q);
                            axios({
                                method: 'post',
                                url: api.courses,
                                headers: { 'Content-Type': 'application/json' },
                                data: formDatacheckregister,
                            })
                            .then((response) => {
                                this.checkregister = response.data.status
                            });
                        }
                    }
                });
        } else {
            window.setTimeout(function(){ 
                window.location="/login";
                // location.reload();
            } ,0)
        }
    },
    methods: { 
    payment () {
        var formDataPayment = new FormData();
            formDataPayment.append('action', 'uploadpayment');
            formDataPayment.append('course_id', this.$route.params.id);
            formDataPayment.append('users_id', $('#users_id').val());
            formDataPayment.append('course_shoping_id', $('#course_shoping_id').val());
            formDataPayment.append('file', $('#imgpayment')[0].files[0]);
            formDataPayment.append('bank', $('#bank').val());
            formDataPayment.append('detail', $('#detail').val());
            formDataPayment.append('date', $('#date').val());
            formDataPayment.append('time', $('#time').val());
        if ( $('#bank').val() == '') {
            Swal.fire({
                position: 'center',
                icon: 'error',
                // imageUrl: api.imageUrl,
                title: 'ระบุธนาคาร',
                showConfirmButton: true,
                timer: 3000,
            })
        } else if ( $('#date').val() == '') {
            Swal.fire({
                position: 'center',
                // imageUrl: api.imageUrl,
                icon: 'error',
                title: 'กรุณาระบุวันที่',
                showConfirmButton: true,
                timer: 3000,
            })
        } else if ( $('#time').val() == '') {
            Swal.fire({
                position: 'center',
                // imageUrl: api.imageUrl,
                icon: 'error',
                title: 'กรุณาระบุเวลา',
                showConfirmButton: true,
                timer: 3000,
            })
        } else if ( $('#imgpayment').val() == '') {
            Swal.fire({
                position: 'center',
                // imageUrl: api.imageUrl,
                icon: 'error',
                title: 'กรุณาอัพโหลดรูปภาพ',
                showConfirmButton: true,
                timer: 3000,
            })
        } else {
            axios({
                method: 'post',
                url: api.uploadpayment,
                headers: { 'Content-Type': 'application/json' },
                data: formDataPayment,
                })
                .then((response) => {
                if ( response.data.status === 'success') {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: response.data.msg,
                            showConfirmButton: true,
                            timer: 5000,
                        },window.setTimeout(function(){ 
                            location.reload();
                        } ,2000))      
                            var formDatagmail = new FormData();
                                formDatagmail.append('action', 'purchasesgmail');
                                formDatagmail.append('course_id', this.$route.params.id);
                                formDatagmail.append('users_id', this.users_id_q);
                                axios({
                                    method: 'post',
                                    url: api.gmailuploadpayment,
                                    headers: { 'Content-Type': 'application/json' },
                                    data: formDatagmail,
                        })       
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        // imageUrl: api.imageUrl,
                        title: response.data.msg,
                        showConfirmButton: true,
                        timer: 10000,
                    },function(){ 
                        
                    })
                    }
                });
        }
    }
  }
}
</script>
<style>
    .errormargin {
        margin-top: 200px;
        margin-bottom: 300px;
    }
    .errormarginpayment {
        margin-top: 100px;
        margin-bottom: 200px;
    }
    button.btn-submitpayment {
        background-color: #ffffff; /* Green */
        border: 3px solid #4CAF50;
        color: #4CAF50;
        padding: 10px 100px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition: 0.3s;
    }
</style>