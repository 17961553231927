<template>
<div class="main">
    <div v-if="session_status === 'error'">
    <Topbar/>
        <div class="container">
            <br>
            <article class="card-body mx-auto">
                <div class="errormargin">
                    <!-- <h1 class="card-title text-center">404</h1>
                    <h2 class="card-title text-center">ERROR! PAGE NOT FOUND.</h2> -->
                    <p></p>
                </div>
            </article>
        </div>
    <Footer/>
    </div>
    <div v-else-if="session_status === 'success'">
    <Topbar/>
          <div class="container">           
            <div class="row"> 
                  <div class="container"> 
                      <div class="col-md-12"> 
                          <h3>เกียรติบัตรของฉัน</h3>
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col" class="text-center"><i class="icon-reorder"></i></th>
                                <th scope="col">ชื่อคอร์ส</th>
                                <th scope="col" class="text-center">รายละเอียด</th>
                                <!-- <th scope="col">วัน เดือน ปี ที่ลงทะเบียน</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, c_no) in mycourse" :key="data.course_id" :value="data.course_id">
                                <td class="text-center">{{ c_no+=1 }}</td>
                                <td><i class="fa fa-certificate"></i> {{ data.course_name }}</td>
                                <!-- Detail -->
                                <td class="text-center">
                                    <a :href="`../certificatecourse?certificate=${data.course_id}`" class="btn btn-info btn-detail" role="button">ดูรายละเอียด</a>
                                </td> 
                              </tr>
                            </tbody>
                          </table><br> 
                      </div>
                  </div>               
            </div>
          </div>
        <div>
             <Footer/>
        </div>     
    </div>
</div>
</template>
<script>
import axios from 'axios';
// import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
    name: 'Mymycertificate',
    data() {
        return {
            id : this.$route.params.id,
            session_status: '',
            mycourse: [],
            c_no: 0,
        }
    },
    components: {
        Topbar,
        Footer
    },
    created() {
        // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    // console.log(response.data.session_status)
                    if ( response.data.status == 'error') {
                      window.setTimeout(function(){ 
                          window.location="/login";
                          // location.reload();
                      } ,0)
                    } else {
                        if ( response.data.confirm_account === 'false') {
                            window.location="/confirm";
                        } else {
                    //   console.log(response.data.users_id)
                      var formDatamycourse = new FormData();
                          formDatamycourse.append('action', 'getmycourse');
                          formDatamycourse.append('users_id', response.data.users_id);
                          axios({
                              method: 'post',
                              url: api.mycertificate,
                              headers: { 'Content-Type': 'application/json' },
                              data: formDatamycourse,
                          })
                          .then((res) => {
                              this.mycourse = res.data.result
                          });
                        }

                    }
                });
        } else {
            window.location="/login";
        }
    },
}
// function thaidate
</script>
<style>
    .errormargin {
        margin-top: 200px;
        margin-bottom: 300px;
    }
    a.btn-detail {
        background-color: #ffffff; /* Green */
        border: 2px solid #55cfff;
        color: #55cfff;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition: 0.3s;
    }
</style>