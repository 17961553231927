<template>
<div class="main">
  <div>
    <Topbar/>
        <div class="container">
        <br>
        <article class="card-body mx-auto" style="max-width: 500px;">
          <div v-if="status == 'success'">
          <h4 class="card-title text-center">ตั้งรหัสผ่านใหม่</h4>
          <p></p>
            <form @submit.prevent="forgotpassword"> 
              <div class="form-group">
                <label class="cols-sm-2 control-label">รหัสผ่านใหม่</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-lock fa-2x"></i> </span>
                  <input v-model="newpassword" class="form-control" placeholder="รหัสผ่านใหม่" type="password" id='newpassword' required>
                </div>
              </div>
              <div class="form-group">
                <label class="cols-sm-2 control-label">ยืนยันรหัสผ่าน</label>
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-lock fa-2x"></i> </span>
                  <input v-model="confirmnewpassword" class="form-control" placeholder="ยืนยันรหัสผ่าน" type="password" id='confirmnewpassword' required>
                </div>
              </div>
              <hr>                                   
              <div class="form-group">
                <button type="button" id="submit" class="btn btn-success" @click="changepassword">ตั้งรหัสผ่านใหม่</button>
              </div>                                                                
            </form>
          </div>
          <div v-else>
            <div class="errormargin">
                <h2 class="card-title text-center">ลิงค์หมดอายุ</h2>
                <p></p>
            </div>
          </div>
        </article><br><br>
      </div> 
    <Footer/>
  </div>
</div>
</template>
<script>
import $ from 'jquery'
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Main',
  data() {
    return {
      newpassword: '',
      confirmnewpassword: '',
      status: '',
      email: '',
    }
  },
  components: {
    Topbar,
    Footer
  },
  
    created() {
      var accesstoken = this.$route.query.r;
      if ( accesstoken !== null) {
          var formDataaccesstoken = new FormData();
              formDataaccesstoken.append('accesstoken', accesstoken);
              axios({
                  method: 'post',
                  url: api.resetcheck,
                  headers: { 'Content-Type': 'application/json' },
                  data: formDataaccesstoken,
              })
              .then((response) => {
                this.status = response.data.status
                this.email = response.data.email
                // console.log(response.data.status)
                // console.log(response.data.email)

              });
      }
    },
  methods: { 
    changepassword () {
        var formDataupdate = new FormData();
            formDataupdate.append('action', 'resetpassword');
            formDataupdate.append('newpassword', $('#newpassword').val());
            formDataupdate.append('confirmnewpassword', $('#confirmnewpassword').val());
            formDataupdate.append('email', this.email);
            if  ( $('#newpassword').val() == '' ) {
              Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "กรุณากรอกรหัสผ่าน",
                  showConfirmButton: true,
                  timer: 10000,
              })
            } else if  ( $('#newpassword').val() !== $('#confirmnewpassword').val() ) {
              Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "รหัสผ่านไม่ตรงกัน",
                  showConfirmButton: true,
                  timer: 10000,
              })
            } else {
              axios({
                method: 'post',
                url: api.changepassword,
                headers: { 'Content-Type': 'application/json' },
                data: formDataupdate,
              })
              .then((response) => {
                  if ( response.data.status === 'success') {
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: "เปลี่ยนรหัสผ่านเรียบร้อย",
                      showConfirmButton: true,
                      timer: 3000,
                    },window.setTimeout(function(){
                        window.location="/login";
                    } ,2000))
                  } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "รหัสผ่านเดิมไม่ถูกต้อง กรุณากรอกรหัสผ่านเดิมใหม่",
                        showConfirmButton: true,
                        timer: 10000,
                    })
                  }
              });
            }
    }
  }
}
</script>
<style>
.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 45em;
    max-width: 100%;
    padding: 5em;
    padding-top: 5em;
    padding-right: 5em;
    padding-bottom: 5em;
    padding-left: 5em;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
}
select.form-control{
      border-radius: 50px;
      background-size: cover;
      width: 100%;
      height: auto;
}
</style>