import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/components/Main.vue'
import Error from '@/components/Error.vue'
import Login from '@/components/login/Login.vue'
import Register from '@/components/register/Register.vue'
import Forgotpassword from '@/components/forgotpassword/Forgotpassword.vue'
import Resetpassword from '@/components/resetpassword/Resetpassword.vue'
import Confirmaccount from '@/components/confirmaccount/Confirmaccount.vue'

import Mycourse from '@/components/mycourse/Mycourse.vue'
import MyPayment from '@/components/mycourse/MyPayment.vue'
import Mycertificate from '@/components/mycertificate/Mycertificate.vue'
import Profile from '@/components/profile/Profile.vue'
import Payment from '@/components/profile/Uploadpayment.vue'
import Courses from '@/components/courses/Courses.vue'
import Coursesearch from '@/components/courses/Coursesearch.vue'
import Coursespreview from '@/components/courses/Coursespreview.vue'
import Learning from '@/components/learning/Learning.vue'
import Media from '@/components/media/Media.vue'
import Pretestq from '@/components/pretestq/Pretestq.vue'
import Posttestq from '@/components/posttestq/Posttestq.vue'
import Session from '@/components/session_storage/Session_storage.vue'
import Logout from '@/components/Logout.vue'
import Video from '@/components/video.vue'
import Confirm from '@/components/Confirm.vue'
import Certificatecourse from '@/components/certificatecourse/certificatecourse.vue'
import Checkcertificate from '@/components/Checkcertificate.vue'
import History from '@/components/History.vue'



Vue.use(VueRouter)
const routes = [
  { path: "*", component: Error },
  { path: '/assets/creative/',name: 'assetsc', component: Error },
  { path: '/',name: 'main',component: Main },
  { path: '/login',name: 'login',component: Login },
  { path: '/Confirm',name: 'confirm',component: Confirm },
  { path: '/register',name: 'register',component: Register },
  { path: '/forgotpassword',name: 'forgotpassword',component: Forgotpassword },
  { path: '/reset',name: 'resetpassword',component: Resetpassword },
  { path: '/confirmaccount',name: 'confirmaccount',component: Confirmaccount },
  { path: '/mycourse',name: 'mycourse',component: Mycourse },
  { path: '/mypayment',name: 'mypayment',component: MyPayment },
  { path: '/mycertificate',name: 'mycertificate',component: Mycertificate },
  { path: '/profile',name: 'profile',component: Profile },
  { path: '/payment/:id',name: 'payment',component: Payment },
  { path: '/course/:id',name: 'courses',component: Courses },
  { path: '/learning/:id',name: 'learning',component: Learning },
  { path: '/courses',name: 'coursespreview',component: Coursespreview },
  { path: '/search',name: 'coursesearch',component: Coursesearch },
  { path: '/pretestq/:id',name: 'pretestq',component: Pretestq },
  { path: '/posttestq/:id',name: 'posttestq',component: Posttestq },
  // { path: '/courses?',name: 'coursespreview',component: Coursespreview },
  { path: '/session',name: 'session',component: Session },
  { path: '/media',name: 'media',component: Media },
  { path: '/video/:id',name: 'video',component: Video },
  { path: '/certificatecourse',name: 'certificatecourse',component: Certificatecourse },
  { path: '/checkcertificate',name: 'checkcertificate',component: Checkcertificate },
  { path: '/history',name: 'history',component: History },
  { path: '/logout',name: 'logout',component: Logout },

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
