<template>
<div class="main">
  <div>
    <Topbar/>
          <div class="container">           
            <div class="row"> 
              <div class="container"> 
              <article class="card-body mx-auto" style="max-width: 500px;">
                <h3 class="card-title text-center">เข้าสู่ระบบ</h3>
                <p></p>
                  <form @submit.prevent="login"> 
                    <div class="form-group">
                      <label class="cols-sm-2 control-label">ชื่อผู้ใช้งาน</label>
                      <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fa fa-user fa-2x"></i> </span>
                        <input v-model="username" class="form-control" placeholder="ชื่อผู้ใช้งาน" type="text" required autofocus>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="cols-sm-2 control-label">รหัสผ่าน</label>
                      <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fa fa-lock fa-2x"></i> </span>
                        <input v-model="password" class="form-control" placeholder="รหัสผ่าน" type="password" autocomplete="on" required>
                      </div>
                    </div>
                    <hr>                                   
                    <div class="form-group">
                      <button type="submit"  class="btn btn-info btn-block"> เข้าสู่ระบบ </button><br>
                      <div class="text-left"><a href="/forgotpassword"> ลืมรหัสผ่าน</a></div>
                    </div>                                                                
                  </form>
                  <hr>
                    <div class="text-center">ถ้าหากยังไม่มีบัญชีผู้ใช้งาน</div><br>
                    <div class="form-group">
                      <input type="button" class="btn btn-success btn-block" onclick="location.href='/register';" value=" สร้างบัญชีผู้ใช้งาน " />
                    </div>  
              </article><br><br>
              </div>
            </div>                
          </div>
    <Footer/>
  </div>
</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
    }
  },
  components: {
    Topbar,
    Footer
  },
  created() {
            // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    // console.log(response.data.session_status)
                    if ( response.data.status !== 'error') {
                        window.location="/";
                    }
                });
        }

  },
  methods: { 
    login () {
        var formData = new FormData();
            formData.append('username', this.username);
            formData.append('password', this.password);
        axios({
          method: 'post',
          url: api.login,
          headers: { 'Content-Type': 'application/json' },
          data: formData,
        })
        .then((response) => {
          if ( response.data.status === 'success') {
            localStorage.setItem('accesstoken', response.data.accesstoken);
            window.location="/";
          } else {
            Swal.fire({
                position: 'center',
                // imageUrl: api.imageUrl,
                icon: 'error',
                title: response.data.message,
                showConfirmButton: true,
                timer: 5000000,
              })
            }
        }, (error) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: error,
              showConfirmButton: true,
              timer: 5000000
            })
        });
    }
  }
}
</script>
<style>
  .swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 40em;
    max-width: 100%; 
    padding: 1.25em;
      padding-top: 3.25em;
      padding-right: 1.25em;
      padding-bottom: 3.25em;
      padding-left: 1.25em;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
  }
</style>