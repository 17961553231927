<template>
    <div v-if="session_status === 'error'">
    <Topbar/>
        <div class="customcontent"></div>
        <div class="container">
            <br>
            <div class="card bg-light">
            <article class="card-body mx-auto">
                <div class="errormargin">
                    <!-- <h1 class="card-title text-center">404</h1>
                    <h2 class="card-title text-center">ERROR! PAGE NOT FOUND.</h2> -->
                    <p></p>
                </div>
            </article>
            </div>
        </div>
    <Footer/>
    </div>
    <div v-else-if="session_status === 'success'">
    <Topbar/>
        <div class="customcontent"></div>
          <div class="container">           
            <div class="row"> 
              <div class="card bg-light" style="width: auto;">
                  <div class="container"> 
                      <div class="col-md-12"> 
                          <hr>
                          halo


                      </div>
                  </div>
              </div>                
            </div>
          </div>
        <div>
             <Footer/>
        </div>     
    </div>
</template>
<script>
import axios from 'axios';
// import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
    name: 'Pretestq',
    data() {
        return {
            id : this.$route.params.id,
            session_status: '',
        }
    },
    components: {
        Topbar,
        Footer
    },
    created() {
        // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    this.users_id_q = response.data.users_id
                    console.log(response.data.session_status)
                    if ( response.data.status == 'error') {
                      window.setTimeout(function(){ 
                          window.location="/login";
                          // location.reload();
                      } ,0)
                    } 
                });
        } else {
            window.setTimeout(function(){ 
                window.location="/login";
                // location.reload();
            } ,0)
        }
    },
    methods: { 

    }
}
</script>
<style>
    .errormargin {
        margin-top: 200px;
        margin-bottom: 300px;
    }
</style>