<template>
<div class="main">
    <Topbar/>
    <div v-if="session_status === 'error'">
        <div class="container">
            <br>
            <article class="card-body mx-auto">
                <div class="errormargin">
                    <!-- <h1 class="card-title text-center">404</h1>
                    <h2 class="card-title text-center">ERROR! PAGE NOT FOUND.</h2> -->
                    <p></p>
                </div>
            </article>
        </div>
    </div>
    <div v-else-if="session_status === 'success'">
        <div v-if="courseapprove === 'unapprove'">
            <div class="container">
                <br>
                <article class="card-body mx-auto">
                    <div class="errormargin">
                        <h2 class="card-title text-center">{{ message }}</h2>
                    </div>
                </article>
            </div>  
        </div>
        <div v-if="courseapprove === 'approve'">
          <div class="container">           
            <div class="row"> 
                  <div class="container"> 
                      <div class="col-md-12"> 
                          <div v-for="datas in courses" :key="datas.course_id">
                            <h4>{{ datas.course_name }}</h4>
                          </div>

                          <hr class="hr">
                          <div class="row">  
                                <div class="col col-sm-6 text-left">
                                    สรุปภาพรวม
                                </div>
                                <div class="col col-sm-6 text-right">
                                    <!-- <button class="btn btn-info">Refresh</button>-->
                                    <button class="btn btn-info" role="button" @click="reloadPage">Refresh</button>
                                </div>
                          </div><br><br>
                            <div class="row">      
                                <div class="col col-sm-12 col-md-12 col-lg-4 text-center">        
                                    <div>          
                                        <div>ความก้าวหน้าในการเรียน</div>
                                        <hr class="hr1">  
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
                                            aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" :style="`width:${progress}%`">
                                                {{ progress }}%
                                            </div>
                                        </div>        
                                        <div>            
                                            <div>              
                                              <span><h1 class="c-color">{{ progress }}%</h1></span>
                                              <span></span>            
                                            </div>            
                                            <div>ผ่านเกณฑ์ {{ per_success }} %</div>          
                                        <hr class="hr1">
                                        </div>
                                        <div>            
                                            <div>              
                                                <a :href="`learning/${ learningid }`"><button class="btn-learnings"><i class="icon-facetime-video"></i> เข้าสู่บทเรียน</button></a>          
                                            </div>  
                                            <hr class="hr1">  
                                        </div>         
                                    </div>     
                                </div>      
                                <div class="col col-sm-12 col-md-12 col-lg-4 text-center">        
                                    <div>          
                                        <div>แบบทดสอบก่อนเรียน</div> 
                                        <hr class="hr1">    
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                                            aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" :style="`width:${score_b_per}%`">
                                                {{ score_b_per }}%
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <span><h1 class="c-color"> {{ score_b_per }}%</h1></span>
                                            </div><br>
                                        <hr class="hr1">
                                        </div>
                                        <div>
                                            <div v-if="example_course_pt == 'false'">
                                                <button class="btn-pretest">ไม่มีแบบทดสอบก่อนเรียน</button>
                                            </div>
                                            <div v-else>
                                                <a :href="`pretestq/${ learningid }`"><button class="btn-pretest"><i class="icon-book"></i> แบบทดสอบก่อนเรียน</button></a>
                                            </div>
                                            <hr class="hr1">
                                        </div>
                                    </div>      
                                </div>      
                                <div class="col col-sm-12 col-md-12 col-lg-4 text-center">        
                                    <div>          
                                        <div>แบบทดสอบหลังเรียน</div> 
                                        <hr class="hr1">   
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-warning progress-bar-striped active" role="progressbar"
                                            aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" :style="`width:${score_a_per}%`">
                                                {{ score_a_per }}%
                                            </div>
                                        </div>       
                                        <div>            
                                            <div>              
                                                <span><h1 class="c-color"> {{ score_a_per }}%</h1></span>
                                            </div>            
                                            <div>ผ่านเกณฑ์ {{ example_quiz_percent }} % </div>          
                                        </div>
                                    <hr class="hr1">            
                                    </div> 
                                    <div>            
                                        <div>              
                                            <div v-if="example_course_po == 'false'">
                                                <button class="btn-pretest">ไม่มีแบบทดสอบหลังเรียน</button>
                                            </div>
                                            <div v-else>
                                                <a :href="`posttestq/${ learningid }`"><button class="btn-posttest"><i class="icon-book"></i> แบบทดสอบหลังเรียน</button></a> 
                                            </div>
                                        </div> 
                                        <hr class="hr1">           
                                    </div>                                          
                                </div>    
                            </div>
                            <br>รายละเอียด
                          <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">#</th>
                                    <th scope="col">Learning Path</th>
                                    <th scope="col" class="text-center">Progress</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, c_no) in getlearning" :key="data.leaning_path_id">
                                    <td class="text-center">{{ c_no+=1 }}</td>
                                    <td>{{ data.leaning_path_name }}</td>
                                    <!-- progress -->
                                    <td class="text-center">
                                        <div v-if="data.successpercent == null">
                                            0%
                                        </div>
                                        <div v-else>
                                            {{ data.successpercent }}%
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                          </table><br>
                          <div class="row text-center">
                            <hr class="hr">
                                <div v-if="this.progress >= this.per_success">
                                    <!-- <a :href="`https://mli.pcru.ac.th/api/pdf/${refid}.pdf`"  class="btn btn-info detail-cert" role="button">ดาวน์โหลดเกียรติบัตร</a> -->
                                    <button  class="btn btn-info detail-cert" role="button" @click="download"  >ดาวน์โหลดเกียรติบัตร </button>
                                </div>
                                <div v-else-if="this.progress == '100'">
                                    <!-- <a :href="`https://mli.pcru.ac.th/api/pdf/${refid}.pdf`"  class="btn btn-info detail-cert" role="button">ดาวน์โหลดเกียรติบัตร</a> -->
                                    <button  class="btn btn-info detail-cert" role="button" @click="download"  >ดาวน์โหลดเกียรติบัตร </button>
                                </div>
                                <div v-else>
                                    <a class="btn btn-danger btn-undownloadcert" role="button">ยังเรียนไม่ผ่านเกณฑ์</a>
                                </div>
                            <hr class="hr">
                          </div><br><br>
                      </div>
                  </div>              
            </div>
          </div>
        </div>
    </div>
    <Footer/>
</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
    name: 'Certificatecourse',
    data() {
        return {
            id : this.$route.params.id,
            learningid: this.$route.query.certificate,
            session_status: '',
            getlearning: [],
            courses: [],
            users_id_q: '',
            approve:'',
            progress: '',
            per_success: '',
            example_quiz_percent : '',
            score_b_per: '',
            score_a_per: '',
            courseapprove: 'unapprove',
            message: '',
            ex_result: '',
            c_no: 0,
            example_course_po : '',
            example_course_pt : '',
            getlearningstatus: '',
            certstatus : '',
            refid : '',
        }
    },
    components: {
        Topbar,
        Footer
    },
    created() {
        this.getcourse();

        // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    this.users_id_q = response.data.users_id;
                    if ( response.data.status == 'error') {
                      window.setTimeout(function(){ 
                          window.location="/login";
                      } ,0)
                    } else {
                        if ( response.data.confirm_account === 'false') {
                            window.location="/confirm";
                        } else {
                            // check approve
                            var formDataCApprove = new FormData();
                                formDataCApprove.append('action', 'checkapprovevideo');
                                formDataCApprove.append('course_id', this.$route.query.certificate);
                                formDataCApprove.append('users_id', response.data.users_id);
                                axios({
                                    method: 'post',
                                    url: api.courses,
                                    headers: { 'Content-Type': 'application/json' },
                                    data: formDataCApprove,
                                })
                                .then((response) => {
                                    this.courseapprove = response.data.status
                                    this.message = response.data.message
                                })
                            // get registercourse
                            var formDatacheckregistercourse = new FormData();
                                formDatacheckregistercourse.append('action', 'checkregistercourse');
                                formDatacheckregistercourse.append('course_id', this.$route.query.certificate);
                                formDatacheckregistercourse.append('users_id', response.data.users_id);
                                axios({
                                    method: 'post',
                                    url: api.purchases,
                                    headers: { 'Content-Type': 'application/json' },
                                    data: formDatacheckregistercourse,
                                })
                                .then((response) => {
                                    this.checkregistercourse = response.data.status
                                    this.approve = response.data.approve
                                    if ( response.data.approve === 'yes') {
                                    var formDataLearningdetail = new FormData();
                                        formDataLearningdetail.append('action', 'getlearning');
                                        formDataLearningdetail.append('course_id', this.$route.query.certificate);
                                        formDataLearningdetail.append('users_id', this.users_id_q);
                                        axios({
                                            method: 'post',
                                            url: api.getlearning,
                                            headers: { 'Content-Type': 'application/json' },
                                            data: formDataLearningdetail,
                                        })
                                        .then((response) => {
                                            this.getlearningstatus = response.data.status
                                            this.getlearning = response.data.result
                                        });
                                    // get progressbar
                                    var formDataProgress = new FormData();
                                        formDataProgress.append('action', 'progress');
                                        formDataProgress.append('id', this.$route.query.certificate);
                                        formDataProgress.append('users_id', this.users_id_q);
                                        axios({
                                            method: 'post',
                                            url: api.progress,
                                            headers: { 'Content-Type': 'application/json' },
                                            data: formDataProgress,
                                        })
                                        .then((resp) => {
                                            this.progress = resp.data.prog
                                            this.per_success = resp.data.per_success
                                        });
                                    // get score
                                    var formDataScore = new FormData();
                                        formDataScore.append('action', 'getscore');
                                        formDataScore.append('course_id', this.$route.query.certificate);
                                        formDataScore.append('users_id', this.users_id_q);
                                        axios({
                                            method: 'post',
                                            url: api.score,
                                            headers: { 'Content-Type': 'application/json' },
                                            data: formDataScore,
                                        })
                                        .then((res) => {
                                            this.score_b_per = res.data.score_b_per
                                            this.score_a_per = res.data.score_a_per
                                            this.example_quiz_percent = res.data.example_quiz_percent
                                            this.ex_pre_result = res.data.ex_pre_result
                                            this.ex_post_result = res.data.ex_post_result
                                            this.example_course_pt = res.data.example_course_pt
                                            this.example_course_po = res.data.example_course_po
                                            // gencertificate on load page
                                            this.gencertificate( this.users_id_q );
                                        });
                                }
                            });
                        }
                        
                    }
                });
        } else {
            window.location="/login"; 
        }
    },
    methods: {
        download(){
            if ( this.ex_pre_result === 'notexam') {
                // alert("กรุณาทำแบบทดสอบก่อนเรียน")
                Swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: "กรุณาทำแบบทดสอบก่อนเรียน",
                      showConfirmButton: true,
                      timer: 10000,
                })                
            } else if ( this.ex_post_result === 'not' ) {
                // alert("คุณยังไม่ได้ทำแบบทดสอบหลังเรียน/ทำแบบทดสอบไม่ผ่านเกณฑ์")
                Swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: "คุณยังไม่ได้ทำแบบทดสอบหลังเรียน<br>หรือทำแบบทดสอบไม่ผ่านเกณฑ์",
                      showConfirmButton: true,
                      timer: 10000,
                })
            } else if ( this.certstatus == "detailnotfound") {
                Swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: "กรุณาติดต่อผู้ดูแลระบบ <br>เนื่องจากยังไม่ได้กำหนด<br>ลายเซ็นต์เกียรติบัตร",
                      showConfirmButton: true,
                      timer: 10000,
                })               
            } else {
                window.open("https://mli.pcru.ac.th/api/pdf/"+this.refid+".pdf")
            }
        },
        reloadPage(){
            window.location.reload()
        },
        getcourse() {
        var formData = new FormData();
            formData.append('action', 'getcourselearning');
            formData.append('id', this.$route.query.certificate);
            axios({
                method: 'post',
                url: api.courses,
                headers: { 'Content-Type': 'application/json' },
                data: formData,
            })
            .then((response) => {
                this.courses = response.data.result
                // console.log(response.data)
            });
        },
        gencertificate ( $users_id_q ) {
            if ( this.ex_pre_result === 'notexam' ) {
                // console.log("กรุณาทำแบบทดสอบก่อนเรียน")
            } else if ( this.ex_post_result === 'not' ) {
                // console.log("คุณยังไม่ได้ทำแบบทดสอบหลังเรียน/ทำแบบทดสอบไม่ผ่านเกณฑ์")
            } else {
                var formDatagencertificate = new FormData();
                    formDatagencertificate.append('action', 'gencertificate');
                    formDatagencertificate.append('course_id', this.$route.query.certificate);
                    formDatagencertificate.append('users_id', $users_id_q);
                    // console.log(this.users_id_q)
                    axios({
                        method: 'POST',
                        url: api.gencert,
                        headers:  { 
                                    'Content-Type': 'application/json',
                                    'Access-Control-Allow-Headers': '*',
                                    'Access-Control-Allow-Origin': '*',
                                    'Access-Control-Allow-Methods' : 'GET, POST',
                                    },
                        data: formDatagencertificate,
                    })
                    .then((res) => {
                        this.ref = res.data.ref
                        this.certstatus = res.data.status
                            if (this.certstatus == "detailnotfound") {
                                this.refid = "detailnotfound"
                            } else {
                                var formDatarefid = new FormData();
                                    formDatarefid.append('action', 'refid');
                                    formDatarefid.append('course_id', this.$route.query.certificate);
                                    formDatarefid.append('users_id', this.users_id_q);
                                    axios({
                                        method: 'POST',
                                        url: api.gencert,
                                        headers:  { 
                                                    'Content-Type': 'application/json',
                                                    'Access-Control-Allow-Headers': '*',
                                                    'Access-Control-Allow-Origin': '*',
                                                    'Access-Control-Allow-Methods' : 'GET, POST',
                                                    },
                                        data: formDatarefid,
                                    })
                                    .then((res) => {
                                        this.refid = res.data.ref
                                    }) 
                            }
                       
                    });
            }            
        }
    }
}
</script>
<style>
    .errormargin {
        margin-top: 200px;
        margin-bottom: 300px;
    }
    .hrheader {
        border-top: 1px solid rgb(0, 189, 9);
    }
    .hr {
        border-top: 1px solid rgb(184, 184, 184);
    }
    .hr1 {
        border-top: 1px solid rgb(184, 184, 184);
    }
    .c-color {
        color: #1e00c7;
    }
    button.btn-downloadcert {
        background-color: #ffffff; /* Green */
        border: 2px solid #4CAF50;
        color: #4CAF50;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition: 0.3s;
    }
    button.btn-undownloadcert {
        background-color: #ffffff; /* Green */
        border: 2px solid #ff0000;
        color: #ff0000;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition: 0.3s;
    }
    button.btn-pretest {
        background-color: #ffffff; /* Green */
        border: 2px solid #4CAF50;
        color: #4CAF50;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        transition: 0.3s;
    }
    button.btn-posttest {
        background-color: #ffffff; /* Green */
        border: 2px solid #ffd04f;
        color: #ffd04f;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        transition: 0.3s;
    }
    button.btn-learnings {
        background-color: #ffffff; /* Green */
        border: 2px solid #00a2ff;
        color: #00a2ff;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        transition: 0.3s;
    }
</style>