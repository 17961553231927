<template>
  <div>
    <TopbarSession/>
      <div class="customcontent"></div>
        <div class="container">
        <br>
        <div class="card bg-light">
        <article class="card-body mx-auto" style="max-width: 500px;">
          <h4 class="card-title text-center">Session</h4>
          <p></p>
            
        </article>
        </div>
      </div> 
  </div>
</template>
<script>
import TopbarSession from '@/components/Topbar.vue'
export default {
  name: 'Main',
  components: {
    TopbarSession
  },
}
</script>
<style>
</style>