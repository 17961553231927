<template>
  <div>
    <div class="section section-header gradient-light-blue">
      <Topbar />
      <div class="container">
        <div class="row flex-column-center">
          <SlideImage />
        </div>
      </div>
    </div>
    <div class="main">
      <Category list="mainlist" />
      <div class="container products-container">
        <Courseindex />
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Topbar from "@/components/Topbar.vue";
import SlideImage from "@/components/layouts/Slideimage.vue";
import Category from "@/components/layouts/Category.vue";
import Courseindex from "@/components/layouts/courseindex.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Topbar,
    SlideImage,
    Category,
    Courseindex,
    Footer,
  },
};
</script>
<style>

@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@600&display=swap');
#app {
  font-family: 'Prompt', sans-serif;
}

html, body {
    font-family: 'Prompt', sans-serif;
}
</style>