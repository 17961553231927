<template>
  <div class="main">
    <Topbar/>
        <div class="container">           
            <div class="row"> 
                <div class="container"> 
                    <div class="col-md-12"> 
                        <h3>ประวัติการเข้าสู่ระบบ</h3>
                        <table class="table table-hover">
                          <thead>
                              <tr>
                                  <th scope="col" class="text-center"><i class="icon-reorder"></i></th>
                                  <th scope="col">กิจกรรม</th>
                                  <th scope="col"></th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(data, c_no) in historylogin" :key="data.login_history_id">
                                <td class="text-center"> {{ c_no+=1 }}</td>
                                <td>
                                  <i class="icon-check-sign"></i> <b style="color : green">{{ data.activity }} </b>
                                  <i class="icon-time"></i> {{ data.dtext }} <br>
                                  IP: {{ data.ip }}
                                </td>
                                <td>
                                </td>
                              </tr>
                          </tbody>
                        </table><br>
                    </div>
                </div>               
            </div>
        </div>  
    <Footer/>
  </div>
</template>
<script>
import axios from 'axios';
// import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
  name: 'Checkcertificate',
  data() {
    return {
      users_id_q : '',
      historylogin: [],
      c_no: 0,
    }
  },
  components: {
    Topbar,
    Footer
  },
  created() {
    var accesstoken = localStorage.getItem('accesstoken'); 
    var formDataaccess = new FormData();
    formDataaccess.append('accesstoken', accesstoken);
    axios({
      method: 'post',
      url: api.session,
      headers: { 'Content-Type': 'application/json' },
      data: formDataaccess,
    })
    .then((response) => {
        this.session_status = response.data.status
        this.users_id_q = response.data.users_id
        if ( response.data.status == 'error') {
          window.setTimeout(function(){ 
              window.location="/login";
          } ,0)
        } else {
          if ( response.data.confirm_account === 'false') {
              window.location="/confirm";
          } else {
            this.history();
          }
        }
      }
    );
  },  
  methods: {
      history() {
        var formData = new FormData();
          formData.append('action', 'history');
          formData.append('users_id', this.users_id_q);
            axios({ 
              method: 'post',
              url: api.history,
              headers: { 'Content-Type': 'application/json' },
              data: formData,
            })
            .then((response) => {
              this.historylogin = response.data.result
              // console.log(this.historylogin) 
            });
      },
    },
    
}

</script>
<style>
.main .title {
    font-size: 22px;
    font-weight: 300;
    margin: 7px 0;
    color: #000;
}
</style>