<template>
    <div></div>
</template>
<script>
export default {
    name: 'logout',
    data() {
        localStorage.removeItem('accesstoken');
        window.location="/";
    }
}
</script>