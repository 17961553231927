<template>
<div>
  <div class="container">
  <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="info">
          <img src="https://oc.schooldee.info/mockup.png" class="responsive" width="600px" alt="">
        </div>
      </div>    
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="info text-align-center">
          <h1>ระบบอบรมหลักสูตรออนไลน์</h1>

        <form class=""
          itemprop="potentialAction"
          itemscope="itemscope"
          itemtype="https://schema.org/SearchAction"
          action="/search"
          accept-charset="UTF-8"
          method="get"
        >
          <div class="form-group form-search">
            <meta
              itemprop="target"
              content="https://www.creative-tim.com/search?q={q}"
            />
            <input
              name="q"
              id="q"
              class="form-control form-control-search"
              placeholder="ค้นหาคอร์สเรียน"
              itemprop="query-input"
            />
            <button
              type="submit"
              class="btn btn-white btn-round btn-submit"
            >
              <i class="icon-search icon-2x"></i>
            </button>
          </div>
        </form>
        
        <div class="clearfix"></div><br><br>
          <div>
            <h3>
            "อบรมหลักสูตรออนไลน์" <br>
             เปิดโอกาสให้คุณเลือกรูปแบบการเรียนรู้ที่ใช่ เพื่อเข้าถึงทักษะต่างๆ อย่างมีประสิทธิภาพ และสนุกกับคอนเทนต์ที่เราคัดสรรมาให้ เพื่อให้คุณได้รับประสบการณ์ที่ดีที่สุด
            </h3>
          </div>
        </div>
        
      </div>
  </div>
  </div>
</div>
</template>

<script>
export default {
  name: "SlideImage",
};
</script>

<style>
.responsive {
  width: 100%;
  max-width: 600px;
  height: auto;
}
</style>