<template>
<div class="main">
  <div>
    <Topbar/>
    <div v-if="confirm_account === 'true'">
      <div class="container">
        <br>
        <article class="card-body mx-auto text-center">
              <img src="//limooc.pcru.ac.th/verify.png" class="imgresponsive" alt="verify">
              <h4 class="card-title text-center">คุณได้ทำการยืนยันบัญชีผู้ใช้งานไปแล้ว</h4><br><br>
        </article><br><br>
      </div> 
    </div>
    <div v-else>
      <div class="container">
        <br>
        <article class="card-body mx-auto text-center">
              <br><br>
              <img src="//limooc.pcru.ac.th/lock.png" class="imgresponsive" alt="lock" width="100px" height="100px"><br><br><br><br>
              <h4 class="card-title text-center">ระบบได้ส่งการยืนยันบัญชีไปที่ email : {{ email }} แล้ว กรุณาตรวจสอบ email</h4>
              <h4 class="card-title text-center">หากไม่ได้รับ email/ Link หมดอายุ กรุณา <button type="button" class="btn btn-info btn-sendmail" @click="sendemail">Click</button> เพื่อส่ง email อีกครั้ง  </h4><br><br><br><br>
        </article><br><br>
      </div> 
    </div>
    <Footer/>
  </div>
</div>
</template>
<script>

import Topbar from '@/components/Topbar-preview.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
  name: 'confirm',
  data() {
    return {
      prefix: [],
      email: '',
      username: '',
      firstname: '',
      lastname: '',
      confirm_account: '',
    }
  },
  components: {
    Topbar,
    Footer
  }, 
  created() {
      // get id accesstoken
      var accesstoken = localStorage.getItem('accesstoken');
      if ( accesstoken !== null) {
          var formDataaccesstoken = new FormData();
              formDataaccesstoken.append('accesstoken', accesstoken);
              axios({
                  method: 'post',
                  url: api.session,
                  headers: { 'Content-Type': 'application/json' },
                  data: formDataaccesstoken,
              })
              .then((response) => {
                  this.users_id = response.data.users_id
                  if ( response.data.status == 'error') {
                    window.setTimeout(function(){ 
                        window.location="/login";
                    } ,0)
                  } else {
                    var formDataemail = new FormData();
                      formDataemail.append('action', 'getemail');
                      formDataemail.append('users_id', this.users_id);
                      axios({
                          method: 'post',
                          url: api.getemail,
                          headers: { 'Content-Type': 'application/json' },
                          data: formDataemail,
                      })
                      .then((res) => {
                        this.email = res.data.email
                        this.username = res.data.username
                        this.firstname = res.data.firstname
                        this.lastname = res.data.lastname
                        this.confirm_account = res.data.confirm_account
                      })
                  }
              });
      } else {
          window.location="/login";
      }
  },  
  methods: { 
    sendemail () {
    let timerInterval
      Swal.fire({
        title: 'กำลังทำรายการ',
        timer: 1500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          timerInterval = setInterval(() => {
            const content = Swal.getContent()
            if (content) {
              const b = content.querySelector('b')
              if (b) {
                b.textContent = Swal.getTimerLeft()
              }
            }
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })      
      var formDatacheckemail = new FormData();
          formDatacheckemail.append('username', this.username);
        axios({
          method: 'post',
          url: api.emailcheck,
          headers: { 'Content-Type': 'application/json' },
          data: formDatacheckemail,
        })
        .then((resactoken) => {
          if ( resactoken.data.status === 'success') {
            var formDatagmailregister = new FormData();
                formDatagmailregister.append('accesstoken', resactoken.data.accesstoken);
                formDatagmailregister.append('email', this.email); 
                formDatagmailregister.append('firstname', this.firstname); 
                formDatagmailregister.append('lastname', this.lastname);           
            axios({
              method: 'post',
              url: api.gmailregister,
              headers: { 'Content-Type': 'application/json' },
              data: formDatagmailregister,
            })
            .then((res) => {
                if ( res.data.status === 'success') {
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: "ระบบได้ส่งการยืนยันบัญชีไปที่ <br/>email : "+this.email+" <br/> ของคุณเรียบร้อยแล้ว <br/> กรุณายืนยันบัญชี",
                      showConfirmButton: true,
                      timer: 10000,
                    },window.setTimeout(function(){ 
                        location.reload();
                    } ,60000))
                }  else {
                        Swal.fire({
                          position: 'center',
                          icon: 'error',
                          title: "ไม่สามารถส่ง email ได้ในขณะนี้",
                          showConfirmButton: true,
                          timer: 10000,
                        },window.setTimeout(function(){ 
                            // location.reload();
                        } ,5000))                      
                }
            })
          } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: resactoken.data.message,
                showConfirmButton: true,
                timer: 10000,
              })
          }
        })  
    }
  }
}
</script>
<style>
  .errormargin {
      margin-top: 200px;
      margin-bottom: 300px;
  }
  .imgresponsive {
      max-width: 100%;
      height: auto;
  }
    button.btn-sendmail {
        background-color: #ffffff; /* Green */
        border: 2px solid rgb(255, 0, 0);
        color: #ff0000;
        padding: 5px 30px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition: 0.3s;
    }
</style>