<template>
<div class="main">
    <Topbar/>
    <div v-if="session_status === 'error'">
        <div class="container">
            <br>
            <article class="card-body mx-auto">
                <div class="errormargin">
                    <!-- <h1 class="card-title text-center">404</h1>
                    <h2 class="card-title text-center">ERROR! PAGE NOT FOUND.</h2> -->
                    <p></p>
                </div>
            </article>
        </div>
    </div>
    <div v-else-if="session_status === 'success'">
        <div class="container">           
            <div class="row"> 
                <div class="container"> 
                    <div class="col-md-12"> 
                        <h3>แจ้งชำระเงิน</h3>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center"><i class="icon-reorder"></i></th>
                                    <th scope="col">ชื่อคอร์ส</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, c_no) in mycourse" :key="data.course_id" :value="data.course_id">
                                    <td style="width:5%" class="text-center">{{ c_no+=1 }}</td>
                                    <td style="width:70%">
                                        <a style="color: #000000" :href="`../payment/${data.course_id}`">
                                            <i class="icon-book"></i> {{ data.course_name }} <br>
                                            <i class="icon-reorder"></i> {{ data.order_id }} <br>
                                            <div v-if="data.approve === 'true'">
                                                <i class="icon-ok-sign"></i> <b style="color : green"><u>ชำระเงินแล้ว</u></b>
                                            </div>
                                            <div v-else>
                                                <div v-if="data.uploadpayment === 'false'">
                                                    <i class="icon-remove-sign"></i> <b style="color : red"><u>ยังไม่แจ้งชำระเงิน</u></b>
                                                </div>
                                                <div v-else>
                                                    <i class="icon-circle-arrow-up"></i> <b style="color : #ffbb00"><u>แจ้งชำระเงินแล้ว/อยู่ระหว่างการตรวจสอบ</u></b>
                                                </div>
                                            </div>
                                        </a>
                                        <div v-if="data.uploadpayment === 'false'">
                                            <a :href="`../payment/${data.course_id}`"><i class="icon-tags"></i> แจ้งชำระเงิน</a>
                                        </div>
                                    </td>
                                    <!-- <td style="width:20%"> {{ data.order_id }}</td> -->
                                </tr>
                            </tbody>
                        </table><br>
                    </div>
                </div>               
            </div>
        </div>   
    </div>
    <Footer/>
</div>
</template>
<script>
import axios from 'axios';
// import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
    name: 'Mypayment',
    data() {
        return {
            id : this.$route.params.id,
            session_status: '',
            mycourse: [],
            c_no: 0,

        }
    },
    components: {
        Topbar,
        Footer
    },
    created() {
        // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    // console.log(response.data.confirm_account)
                    this.session_status = response.data.status
                    // console.log(response.data.session_status)
                    if ( response.data.status == 'error') {
                      window.setTimeout(function(){ 
                          window.location="/login";
                          // location.reload();
                      } ,0)
                    } else {
                        if ( response.data.confirm_account === 'false') {
                            window.location="/confirm";
                        } else {
                    //   console.log(response.data.users_id)
                            var formDatamycourse = new FormData();
                                formDatamycourse.append('action', 'getmycourse');
                                formDatamycourse.append('users_id', response.data.users_id);
                                axios({
                                    method: 'post',
                                    url: api.mycourse,
                                    headers: { 'Content-Type': 'application/json' },
                                    data: formDatamycourse,
                                })
                                .then((res) => {
                                    this.mycourse = res.data.result
                                });
                        }
                    }
                });
        } else {
            window.location="/login";
        }
    },
}
// function thaidate
</script>
<style>
    .errormargin {
        margin-top: 200px;
        margin-bottom: 300px;
    }
    .circlelearning {
        width: 10px; /* ความกว้าง */
        height: 10px; /* ความสูง */
        background: #00ff62; /* สี */
        -moz-border-radius: 70px;
        -webkit-border-radius: 70px;
        border-radius: 70px;
    }
    .circlepayment {
        width: 10px; /* ความกว้าง */
        height: 10px; /* ความสูง */
        background: #ffbb00; /* สี */
        -moz-border-radius: 70px;
        -webkit-border-radius: 70px;
        border-radius: 70px;
    }
    .circleapprove {
        width: 10px; /* ความกว้าง */
        height: 10px; /* ความสูง */
        background: #ff0000; /* สี */
        -moz-border-radius: 70px;
        -webkit-border-radius: 70px;
        border-radius: 70px;
    }
</style>