<template>
<div class="main">
<div>
    <div class="page-loader" v-if="!isloaded">
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
    </div>
    <Topbar/>
            <div v-if="coursestatus === 'success'">
                <div class="container" v-for="data in courses" :key="data.course_id">           
                    <div class="row"> 
                            <div class="container"> 
                                <div class="col-md-8"> 
                                    <div >
                                        <h3>{{ data.course_name }}</h3> 
                                        <h5><i class="fa fa-caret-square-o-down"></i> หมวดหมู่ : {{ data.name_th }}</h5> <br> 
                                        <div v-if="data.video_preview_youtube == ''">
                                            ไม่พบวิดีโอ Preview
                                            <iframe class="responsive-iframe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" width="640px" height="420px"></iframe>
                                        </div>
                                        <div v-else>
                                            <iframe class="responsive-iframe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" :src="data.video_preview_youtube" width="640px" height="420px"></iframe>
                                        </div>
                                        <br>
                                        <p v-if="checkregistercourse !== 'registercourse'"> 
                                            <button type="submit" class="btn btn-danger btn-unregister" @click="registercourse"> ลงทะเบียนเรียนคลิ๊ก  </button>
                                        </p>
                                        <p v-else>
                                            <button type="button" class="btn btn-success btn-register"> ลงทะเบียนไปแล้ว  </button> 
                                            <button type="button" class="btn btn-danger btn-danger" data-toggle="modal" data-target="#exampleModal"> วิธีชำระเงิน  </button>  
                                            <br><p> <font color='red'> {{ msgapprove }} </font>
                                        </p>
                                    </div> 
                                </div> 
                                <!-- modal -->
                                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">วิธีชำระเงิน</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {{ paymentdata }} <br>
                                        <b><u>
                                            <div v-for="price in courses" :key="price.course_id">
                                                <!-- ยอดชำระ : {{ price.price }} บาท -->
                                            </div>
                                        </u></b>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <a :href="`/payment/${$route.params.id}`"><button href="#profile" type="button" class="btn btn-primary">แจ้งชำระเงิน</button></a>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <!-- modal -->
                                <div class="col-md-4 "> 
                                    <div class="product-details-container"> 
                                        <ul class="nav nav-tabs"> 
                                            <li class="active"> <a class="btn btn-switch" href="#home" data-toggle="tab"> <i class="icon-book"></i> รายละเอียด </a> </li> 
                                            <li><a class="btn btn-switch" href="#profile" data-toggle="tab"> <i class="icon-info-sign"></i> ผู้สอน </a> </li> 
                                        </ul> 
                                        <div class="tab-content">
                                            <div class="tab-pane fade active in" id="home"> 
                                                <div class="row"> 
                                                    <div class="container">
                                                        <div class="product-details"> 
                                                            <i class="icon-time"></i> ช่วงเวลาเรียน 
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-2"></div>
                                                            <div class="col-md-10">{{ data.time_period }}</div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div class="row"> 
                                                    <div class="container">
                                                        <div class="product-details"> 
                                                            <i class="icon-time"></i> วันที่เปิดลงทะเบียน 
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-2"></div>
                                                            <div class="col-md-10">{{ data.s }} - {{ data.e }}</div>
                                                        </div>
                                                    </div> 
                                                </div>                                                
                                                <div class="row"> 
                                                    <div class="container">
                                                        <div class="product-details"> 
                                                            <i class="icon-book"></i> จำนวนเนื้อหา 
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-2"></div>
                                                            <div class="col-md-10">{{ data.lesson_content }}</div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div class="row"> 
                                                    <div class="container">
                                                        <div class="product-details"> 
                                                            <i class="icon-star-empty"></i> กลุ่มเป้าหมาย 
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-2"></div>
                                                            <div class="col-md-10">{{ data.target }}</div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div class="row"> 
                                                    <div class="container">
                                                        <div class="product-details"> 
                                                            <i class="icon-circle-arrow-up"></i> เกณฑ์การเรียน
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-2"></div>
                                                            <div class="col-md-10">{{ data.criterion }}</div>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div> 
                                            <div class="tab-pane fade" id="profile"> 
                                                <div class="row text-center"> 
                                                    <div class="col-md-12" v-for="teach in teacher" :key="teach.teacher_id">
                                                        <div class="row text-center">
                                                            <div class="col-md-12"><img class="logo" :src="`https://mli.pcru.ac.th/adminapi/images/teacherimage/${teach.picture}`" width="60" height="60" /></div><br>
                                                        </div><br>
                                                        <div class="row">
                                                            <div class="col-md-12">{{ teach.teacher_name }} <br> {{ teach.position }} </div>
                                                        </div><br>
                                                    </div> 
                                                </div>
                                            </div> 
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                            <div class="container"> 
                                <div class="col-md-12">
                                    <div class="product-details-container">  
                                        <div class="tab-content">
                                            <div class="row"> 
                                                <div class="col-md-12">
                                                    <div class="product-details"> 
                                                        <i class="fa fa-info-circle"></i> เกี่ยวกับรายวิชา 
                                                    </div>
                                                    <div> {{ data.aboutthecourse }} </div>
                                                </div> 
                                            </div>
                                            <div class="row"> 
                                                <div class="col-md-12">
                                                    <div class="product-details"> 
                                                        <i class="fa fa-info-circle"></i> วัตถุประสงค์ 
                                                    </div>
                                                    <div>{{ data.objectivity }}</div>
                                                </div> 
                                            </div>   
                                            <div class="row"> 
                                                <div class="col-md-12">
                                                    <div class="product-details"> 
                                                        <i class="fa fa-info-circle"></i> เกณฑ์การวัดและประเมินผล 
                                                    </div>
                                                    <div> {{ data.evaluation_criteria }} </div>
                                                </div> 
                                            </div>  
                                            <div class="row"> 
                                                <div class="col-md-12">
                                                    <div class="product-details"> 
                                                        <i class="fa fa-info-circle"></i> หมายเหตุ
                                                    </div>
                                                    <div>{{ data.note }}</div>
                                                </div> 
                                            </div> 
                                            <div class="row"> 
                                                <div class="col-md-12 centerbutton">
                                                    <p v-if="approve === 'yes'"> 
                                                        <br><br><a :href="`${linklearning}`"><button type="submit" class="btn btn-learning"><i class="icon-facetime-video"></i> เข้าสู่บทเรียน </button></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                            </div>               
                    </div>
                </div>
            </div>
            <!-- <div v-else>
                <div class="container">
                <br>
                <article class="card-body mx-auto">
                    <div class="errormargin">
                        <h1 class="card-title text-center">ไม่พบคอร์สเรียน</h1>
                        <h2 class="card-title text-center"></h2>
                        <p></p>
                    </div>
                </article>
                </div> 
            </div> -->
        <div>
    <Footer/>
    </div>     
</div>
</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
    name: 'Courses',
    data() {
        return {
            id : this.$route.params.id,
            courses: [],
            teacher: [],
            checkregistercourse: '',
            msgapprove:'',
            approve:'',
            linklearning:'',
            isloaded: false,
            coursestatus:'',
            users_id_q: '',
            paymentdata: '',
        }
    },
    components: {
        Topbar,
        Footer
    },
    mounted() {
      setInterval(
        document.onreadystatechange = () => {
          if (document.readyState == "complete") { 
          this.isloaded = true;
          } 
        }
      ,1500);
    },
    created() {
        // get course detail
        var formData = new FormData();
            formData.append('action', 'getcourse');
            formData.append('id', this.$route.params.id);
            axios({
                method: 'post',
                url: api.courses,
                headers: { 'Content-Type': 'application/json' },
                data: formData,
            })
            .then((response) => {
                this.courses = response.data.result
                this.coursestatus = response.data.status
                if ( response.data.status !== 'success') {
                    window.location="/error";
                }
                // console.log(response.data.status)
            });
        // get teacher
        var formDatateacher = new FormData();
            formDatateacher.append('action', 'getteacher');
            formDatateacher.append('id', this.$route.params.id);
            axios({
                method: 'post',
                url: api.teacher,
                headers: { 'Content-Type': 'application/json' },
                data: formDatateacher,
            })
            .then((res) => {
                this.teacher = res.data.result
                // console.log(response.data)
            });
        // get bank
        var payData = new FormData();
            payData.append('action', 'howtopay');
            axios({
                method: 'post',
                url: api.pay,
                headers: { 'Content-Type': 'application/json' },
                data: payData,
            })
            .then((r) => {
                this.paymentdata = r.data.payment
            });
        // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    this.users_id_q = response.data.users_id
                    // get registercourse
                    var formDatacheckregistercourse = new FormData();
                        formDatacheckregistercourse.append('action', 'checkregistercourse');
                        formDatacheckregistercourse.append('course_id', this.$route.params.id);
                        formDatacheckregistercourse.append('users_id', response.data.users_id);
                        axios({
                            method: 'post',
                            url: api.purchases,
                            headers: { 'Content-Type': 'application/json' },
                            data: formDatacheckregistercourse,
                        })
                        .then((response) => {
                            this.checkregistercourse = response.data.status
                            this.msgapprove = response.data.msgapprove
                            this.approve = response.data.approve
                            this.linklearning = response.data.linklearning
                        });
                });
        } else {
            // window.location="/login";
        }
    },
    methods: { 
        //button click register
        registercourse () {
            var accesstoken = localStorage.getItem('accesstoken');
            if ( accesstoken !== null) {
                // alert(this.$route.params.id + accesstoken)
                var formData = new FormData();
                formData.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formData,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    if ( response.data.status === 'success') {
                        if ( response.data.confirm_account === 'false') {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                // imageUrl: api.imageUrl,
                                title: "กรุณายืนยันบัญชีผู้ใช้งานก่อนลงทะเบียนเรียน",
                                showConfirmButton: true,
                                timer: 3000,
                            })
                        } else {
                            var dRegisData = new FormData();
                            dRegisData.append('action', "checkdateregistration");
                            dRegisData.append('course_id', this.$route.params.id);
                            axios({
                                method: 'post',
                                url: api.checkdateregistrator,
                                headers: { 'Content-Type': 'application/json' },
                                data: dRegisData,
                            })
                            .then((resregis) => {
                                this.registatus = resregis.data.status
                                if ( resregis.data.status === "errorstart") {
                                    Swal.fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: resregis.data.msg,
                                        showConfirmButton: true,
                                        timer: 10000,
                                    }) 
                                } else if ( resregis.data.status === "errorend") {
                                    Swal.fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: resregis.data.msg,
                                        showConfirmButton: true,
                                        timer: 10000,
                                    }) 
                                } else if ( resregis.data.status === "success") {
                                    let timerInterval
                                    Swal.fire({
                                        title: 'กำลังทำรายการ',
                                        timer: 30000,
                                        timerProgressBar: true,
                                        didOpen: () => {
                                        Swal.showLoading()
                                        timerInterval = setInterval(() => {
                                            const content = Swal.getContent()
                                            if (content) {
                                            const b = content.querySelector('b')
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft()
                                            }
                                            }
                                        }, 100)
                                        },
                                        willClose: () => {
                                        clearInterval(timerInterval)
                                        }
                                    })
                                    var formData = new FormData();
                                    formData.append('action', 'purchases');
                                    formData.append('course_id', this.$route.params.id);
                                    formData.append('users_id', response.data.users_id);
                                    axios({
                                        method: 'post',
                                        url: api.purchases,
                                        headers: { 'Content-Type': 'application/json' },
                                        data: formData,
                                    })
                                    .then((response) => {
                                        this.session_status = response.data.status
                                        if ( response.data.status === 'success') {
                                            Swal.fire({
                                                position: 'center',
                                                icon: 'success',
                                                title: response.data.msg,
                                                showConfirmButton: true,
                                                timer: 3000,
                                            },window.setTimeout(function(){ 
                                                location.reload();
                                            } ,2000))      
                                                var formDatagmail = new FormData();
                                                    formDatagmail.append('action', 'purchasesgmail');
                                                    formDatagmail.append('course_id', this.$route.params.id);
                                                    formDatagmail.append('users_id', this.users_id_q);
                                                    axios({
                                                        method: 'post',
                                                        url: api.purchasesgmail,
                                                        headers: { 'Content-Type': 'application/json' },
                                                        data: formDatagmail,
                                                    })       
                                        } else {
                                            Swal.fire({
                                                position: 'center',
                                                icon: 'error',
                                                title: response.data.msg,
                                                showConfirmButton: true,
                                                timer: 10000,
                                            },function(){ 
                                                // do
                                            })  
                                        }
                                    });

                                }

                            })
                        }
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "กรุณาเข้าสู่ระบบ",
                            showConfirmButton: true,
                            timer: 10000,
                        })
                    }
                } 
            )
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "กรุณาเข้าสู่ระบบ",
                    showConfirmButton: true,
                    timer: 10000,
                })
            }
        }
    },
}
</script>
<style>
    .errormargin {
        margin-top: 200px;
        margin-bottom: 300px;
    }
    img.logo {
        border-radius: 50%;
    }
    .responsive-iframe {
    width: 100%;
    }
    .btn-success {
        color: #ffffff;
        background-color: #5cb85c;
        border-color: #4cae4c;
    }
    .btn-danger {
        color: #ffffff;
        background-color: #FF3B30;
        border-color: #FF3B30;
    }
    .centerbutton { 
        display: flex; justify-content: center; 
    }
    button.btn-register {
        background-color: #ffffff; /* Green */
        border: 2px solid #4CAF50;
        color: #4CAF50;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition: 0.3s;
    }
    button.btn-danger {
        background-color: #ffffff; /* Green */
        border: 2px solid #FF0000;
        color: #FF0000;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition: 0.3s;
    }
    button.btn-unregister {
        background-color: #ffffff; /* Green */
        border: 2px solid rgb(255, 0, 0);
        color: #ff0000;
        /* padding: 15px 150px; */
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition: 0.3s;
    }
    button.btn-learning {
        background-color: #ffffff; /* Green */
        border: 2px solid #580092;
        color: #000000;
        padding: 10px 100px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
        transition: 0.3s;
    }
</style>