<template>
<div class="main">
<div>
    <Topbar/>
    <div>
        <div class="page-loader" v-if="!isloaded">
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        </div>
    </div>
    <div v-if="session_status === 'error'">
        <div class="container">
            <br>
            <article class="card-body mx-auto">
                <div class="errormargin">
                    <!-- <h1 class="card-title text-center">404</h1>
                    <h2 class="card-title text-center">ERROR! PAGE NOT FOUND.</h2> -->
                    <p></p>
                </div>
            </article>
        </div>   
    </div>
    <div v-else-if="session_status === 'success'">
        <div v-if="approve === 'no'">
            <div class="container">
            <br>
                <article class="card-body mx-auto">
                    <div class="errormargin">
                        <h2 class="card-title text-center">กรุณาลงทะเบียนหรือรอการ Approve จากผู้ดูแลระบบก่อน</h2>
                        <p></p>
                    </div>
                </article>
            </div>       
        </div>
        <div v-else>
            <div v-if="status_posttest === 'error'">
                <div class="container">
                <br>
                    <article class="card-body mx-auto">
                        <div class="errormargin">
                            <h2 class="card-title text-center">ไม่พบแบบทดสอบหลังเรียน</h2>
                            <p></p>
                        </div>
                    </article>
                </div>                     
            </div>
            <div v-else>
                <div class="container" v-for="data in courses" :key="data.course_id"> 
                    <div class="row"> 
                            <div class="container"> 
                                <div class="col-md-12"> 
                                    <div >
                                        <h3>{{ data.example_name }}</h3> 
                                        <h4>ผ่านเกณฑ์การทำแบบทดสอบ {{ per }} %</h4>
                                    </div> 
                                </div>
                                <div class="col-md-12"> 
                                    <!-- <hr>
                                    <h4>แบบทดสอบหลังเรียน</h4> -->
                                    <div v-if="successExam === 'errorExam'">
                                        <div data-s="quiz" class="cq-margin-x cq-themes">
                                            <strong>จงเลือกคำตอบที่ถูกที่สุดเพียงข้อเดียว</strong><br>
                                            <strong>***กรุณาทำข้อสอบให้ครบทุกข้อ หากผู้เรียนทำข้อสอบไม่ครบ ระบบจะแจ้งเตือนข้อที่ยังไม่ได้ทำข้อสอบ</strong><br>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="customcontentexam"></div>
                                        <br>
                                        <article class="card-body mx-auto text-center">
                                            <img src="https://oc.schooldee.info/exam.png" class="imgresponsive" alt="posttest">
                                            <h4>
                                                <div>คุณทำแบบทดสอบหลังเรียนไปแล้ว เมื่อวันที่ {{ time }}</div><br>
                                                <div>ตอบถูก  {{ sb }} ข้อ จาก {{ sf }} ข้อ คิดเป็นร้อยละ {{ t_per }} % ผ่านเกณฑ์ {{ per }} % </div><br>
                                                <div>ใช้เวลาทำข้อสอบ {{ diff }}</div>
                                            </h4>
                                            <p></p>
                                        </article>
                                    </div>
                                    <section id="c-qz-body">
                                        <form @submit.prevent="checkquiz">
                                        <div v-for="(item, index) in items" :key="index">
                                            <div class="c-qz-item cvocp-quiz-course-theme" data-qstn-nid="472033">    
                                                <div class="media" data-part="question">      
                                                    <div class="media-left">
                                                    <div data-part="quiz-item-number">{{ index+1 }}.</div>
                                                    </div>      
                                                    <div class="media-body">
                                                        <div data-part="question-body">
                                                            <p>{{ item.title }}</p>
                                                        </div>
                                                    </div>    
                                                </div>    
                                                <div data-part="response">      
                                                    <div v-for="(item2, index2) in item.choicelist" :key="index2">
                                                        <div class="cvocpquiz-multiplechoice-response-wrapper" data-part="choices" data-widget="radio" data-n="1">    
                                                            <div data-part="choice-item" class="">      
                                                                <input type="radio" :value="(index2+1)" :name="`group${item.example_question_id}`" v-model="items[index].answer_select" required />
                                                                <label><span data-part="checkbox-img"></span>{{ item2.value }}</label>    
                                                            </div>         
                                                        </div>   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="successExam === 'errorExam'">
                                            <div class="row">
                                                <div class="col-md-4"></div>
                                                <div class="col-md-4">
                                                    <button type="submit"  class="btn btn-primary btn-block"> ส่งคำตอบ  </button>
                                                </div>
                                                <div class="col-md-4"></div>
                                            </div>
                                        </div>
                                        <br>
                                        </form>
                                    </section>
                                </div>
                            </div>
               
                    </div>
                </div>
            </div>
        </div>   
    </div>    
    <div v-else>
        <div class="container">
            <br>
            <article class="card-body mx-auto">
                <div class="errormargin">
                    <p></p>
                </div>
            </article>
        </div> 
    </div>    
    <Footer/>
</div>
</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import api from '@/urlapi.js'
export default {
    name: 'Posttestq',
    data() {
        return {
            isloaded: false,
            id : this.$route.params.id,
            courses: [],
            checkregistercourse: '',
            session_status: '',
            // registercourse status
            msgapprove:'',
            approve:'',
            linklearning:'',
            // getlearning
            getlearning: [],
            items:[],
            users_id_q: '',
            successExam: '',
            sb: '',
            sf: '',
            time: '',
            per: '',
            t_per: '',
            status_posttest: '',
            time_start_example: '',
            diff: '',
        }
    },
    components: {
        Topbar,
        Footer
    },
    mounted() {
        setInterval(
        document.onreadystatechange = () => {
            if (document.readyState == "complete") { 
            this.isloaded = true;
            } 
        }
        ,4000);
    },
    created() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var time = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0') + ":" + String(today.getSeconds()).padStart(2, '0');
        this.time_start_example = yyyy+'-'+mm+'-'+dd+' '+time

        var formData = new FormData();
            formData.append('action', 'getcourseposttest');
            formData.append('id', this.$route.params.id);
            axios({
                method: 'post',
                url: api.courses,
                headers: { 'Content-Type': 'application/json' },
                data: formData,
            })
            .then((response) => {
                this.courses = response.data.result
                this.status_posttest = response.data.status
            });
        // get id accesstoken
        var accesstoken = localStorage.getItem('accesstoken');
        if ( accesstoken !== null) {
            var formDataaccesstoken = new FormData();
                formDataaccesstoken.append('accesstoken', accesstoken);
                axios({
                    method: 'post',
                    url: api.session,
                    headers: { 'Content-Type': 'application/json' },
                    data: formDataaccesstoken,
                })
                .then((response) => {
                    this.session_status = response.data.status
                    this.users_id_q = response.data.users_id
                    // console.log(response.data.status)
                    if ( response.data.status == 'error') {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "กรุณาเข้าสู่ระบบ",
                            showConfirmButton: false,
                            timer: 5000,
                        },window.setTimeout(function(){ 
                            window.location="/login";
                            // location.reload();
                        } ,3000))
                    } else {
                        if ( response.data.confirm_account === 'false') {
                            window.location="/confirm";
                        } else {                        
                        // get registercourse
                        var formDatacheckregistercourse = new FormData();
                            formDatacheckregistercourse.append('action', 'checkregistercourse');
                            formDatacheckregistercourse.append('course_id', this.$route.params.id);
                            formDatacheckregistercourse.append('users_id', response.data.users_id);
                            axios({
                                method: 'post',
                                url: api.purchases,
                                headers: { 'Content-Type': 'application/json' },
                                data: formDatacheckregistercourse,
                            })
                            .then((response) => {
                                this.checkregistercourse = response.data.status
                                this.approve = response.data.approve
                                console.log(response.data.approve)
                                if ( response.data.approve === 'yes') {       
                                    var formDatacheckExam = new FormData();
                                        formDatacheckExam.append('action', 'checkExamposttest');
                                        formDatacheckExam.append('course_id', this.$route.params.id);
                                        formDatacheckExam.append('users_id', this.users_id_q);
                                        axios({
                                            method: 'post',
                                            url: api.checkExam,
                                            headers: { 'Content-Type': 'application/json' },
                                            data: formDatacheckExam,
                                        })
                                        .then((response) => {
                                            this.successExam = response.data.status
                                            this.sb = response.data.sb
                                            this.sf = response.data.sf
                                            this.time = response.data.time
                                            this.per = response.data.per
                                            this.t_per = response.data.t_per
                                            this.diff = response.data.diff
                                            // console.log(response.data)
                                            if (response.data.status == 'errorExam') {
                                                var formDataExample = new FormData();
                                                formDataExample.append('action', 'getquizposttest');
                                                formDataExample.append('course_id', this.$route.params.id);
                                                axios({
                                                    method: 'post',
                                                    url: api.example,
                                                    headers: { 'Content-Type': 'application/json' },
                                                    data: formDataExample,
                                                })
                                                .then((response) => {
                                                    this.items = response.data.result
                                                    // console.log(response.data.result)
                                                });
                                                
                                            }
                                        })
                                }
                            });
                        }
                    }

                });
        } else {
            window.location.href = "/login"; 
        }
    },
    methods: { 
        checkquiz () {
            // alert(this.$route.params.id)
            var formDatacheckquiz = new FormData();
            var checkquizdata = JSON.stringify(this.items);
            formDatacheckquiz.append('action', 'checkquizposttest');
            formDatacheckquiz.append('items', checkquizdata);
            formDatacheckquiz.append('users_id_q', this.users_id_q);
            formDatacheckquiz.append('time_start_example', this.time_start_example);
            formDatacheckquiz.append('course_id', this.$route.params.id);
            // console.log(checkquizdata)

            axios({
                method: 'post',
                url: api.checkquiz,
                headers: { 'Content-Type': 'application/json' },
                data: formDatacheckquiz,
            })
            .then((response) => {
                this.checkquiz = response.data
                // users_id + course_id + example_id
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "แบบทดสอบหลังเรียน <br> คุณได้คะแนน : "+response.data.counttrue+"/"+response.data.sumquestion+" คะแนน"+"<br>ใช้เวลาทำข้อสอบ<br>"+response.data.diff+"",
                    showConfirmButton: false,
                    timer: 5000,
                },window.setTimeout(function(){
                    location.reload();
                    // window.location="/learning/"+this.$route.params.id;
                } ,5000))
            });


        }
    }
}
</script>
<style>
    .errormargin {
        margin-top: 100px;
        margin-bottom: 200px;
    }
    img.logo {
        border-radius: 50%;
    }
    /*  */
    .cq-themes[data-s='quiz'] {
    background-color: #BEEFFF;
    border-color: #F50057;
    color: #000000;
    }
    [data-s="quiz"] {
        border-radius: 8px;
        border: 3px solid#005d7c;
        padding: 10px;
        color: #018ADA;
    }
    .cq-margin-x {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    /* exam */
    #c-qz-body .c-qz-item {
      position: relative;
      margin: 15px 0;
      padding: 15px 0;
      border-bottom: 1px dotted black;
    }
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    div {
        display: block;
    }
    .media:first-child {
        margin-top: 0;
    }
    .media, .media-body {
        zoom: 1;
        overflow: hidden;
    }
    .media {
        margin-top: 15px;
    }
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    #c-qz-body .c-qz-item [data-part="response"] {
        margin-left: 35px;
    }
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    div {
        display: block;
    }
    #c-body {
        font-family: 'Kanit', sans-serif;
        overflow: auto;
    }
    #c-qz-body .c-qz-item [data-part="marker"] {
        position: absolute;
        top: 120px;
        left: 80%;
        zoom: 0.8;
    }
    #c-qz-body .c-qz-item [data-part="choice-item"] input + label > [data-part="checkbox-img"] {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: -2px 10px 0 0;
        vertical-align: middle;
        background-size: contain;
        cursor: pointer;
    }
    .imgresponsive {
        max-width: 100%;
        height: auto;
    }
</style>